import { z } from "zod";

const fontSchema = z.object({
  url: z.string().url(),
  unicodeRange: z.string(),
});
const consentSchema = z.object({
  type: z.string().refine((val) => val === "consent"),
  id: z.number().min(1),
  name: z.string(),
  description: z.string(),
  cookieCategories: z.array(z.string()),
  isConsentable: z.boolean(),
});
const gtmTagSchema = z.object({
  triggerName: z.string(),
  consentIds: z.array(z.number()),
  fireOnce: z.boolean(),
});

export const configSchema = z.object({
  settings: z.object({
    useTopDomain: z.boolean(),
    catchCMPEvents: z.boolean(),
    useGravitoBackend: z.boolean(),
    syncAfter: z.number(),
    disableConfirmationModal: z.boolean(),
    sdkVersion: z.number(),
    version: z.string(),
  }),
  styles: z.object({
    logoUrl: z.string().url(),
    primaryColor: z.string(),
    secondaryColor: z.string(),
    headerColor: z.string(),
    footerColor: z.string(),
    fonts: z.array(fontSchema),
  }),
  gravitoCMP: z
    .object({
      core: z.object({
        cookieName: z.string(),
        cookieExpiry: z.number(),
        languageCode: z.string().min(2).max(2),
        privacyPolicyUrl: z.string().url(),
        useGCM: z.boolean(),
        adsConsentId: z.union([z.number(), z.literal("")]),
        adsUserDataConsentId: z.union([z.number(), z.literal("")]),
        adsPersonalizationConsentId: z.union([z.number(), z.literal("")]),
        analyticsConsentId: z.union([z.number(), z.literal("")]),
        consents: z.array(consentSchema),
        gtmTags: z.array(gtmTagSchema),
      }),
      languages: z.record(
        z.string(),
        z.object({
          label: z.string(),
          text: z.object({
            declaration: z.object({
              header: z.string(),
              title: z.string(),
              introductionText: z.string(),
              legalFooter: z.string(),
            }),
            details: z.object({
              header: z.string(),
              title: z.string(),
              introductionText: z.string(),
            }),
            commonTerms: z.object({
              acceptAll: z.string(),
              rejectAll: z.string(),
              save: z.string(),
              settings: z.string(),
              cancel: z.string(),
              confirm: z.string(),
              consent: z.string(),
              privacyPolicyLabel: z.string(),
            }),
            confirmationLayer: z.object({
              confirmationForAcceptSelected: z.object({
                heading: z.string(),
                paragraph: z.string(),
              }),
              confirmationForRejectAll: z.object({
                heading: z.string(),
                paragraph: z.string(),
              }),
            }),
            cookieReportLayer: z.object({
              buttonTitle: z.string(),
              heading: z.string(),
              introductionText: z.string(),
              beforeAcceptHeader: z.string(),
              afterAcceptHeader: z.string(),
              nameHeader: z.string(),
              domainHeader: z.string(),
              descriptionHeader: z.string(),
              cookieTypeHeader: z.string(),
              expiryHeader: z.string(),
              httpOnlyHeader: z.string(),
              noCookieDataMessage: z.string(),
              noCookieDataBeforeAcceptingMsg: z.string(),
              noCookieDataAfterAcceptingMsg: z.string(),
              size: z.string(),
              pixelRequestHeader: z.string(),
            }),
          }),
        })
      ),
      style: z.object({
        useCustomHtml: z.boolean(),
        customCSS: z.string(),
      }),
    })
    .optional(),
  tcf: z.object({}).optional(),
  ccpa: z.object({}).optional(),
  privacy: z.object({}).optional(),
  about: z.object({}).optional(),
});

export const getSchema = (name) => {
  switch (name) {
    case "fonts":
      return fontSchema;
    case "consents":
      return consentSchema;
    case "gtmTags":
      return gtmTagSchema;
    default:
      return null;
  }
};
