const schema = {
  mapper: {
    settings: [
      {
        name: "catchCMPEvents",
        type: "checkbox",
        label: "Enable CMP Analytics",
        tooltip:
          "Check this box if you want to get real-time data of CMP stats on the Gravito admin dashboard.",
        required: false,
        default: true,
        xs: 12,
        sm: 12,
      },
      {
        name: "useGCM",
        type: "hidden",
        label: "Use GCM mode",
        tooltip:
          "Check this box if you want to enable Google Consent Mode with your CMP.",
        required: false,
        advanced: true,
        default: true,
        xs: 12,
        sm: 12,
      },
      {
        name: "useGravitoBackend",
        type: "hidden",
        label: "Use Gravito Backend",
        advanced: false,
        tooltip:
          "Check this box if you want to enable Gravito backend capabilities with your CMP",
        required: false,
        default: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "backendUrl",
        type: "hidden",
        label: "Backend URL",
        placeholder: "Enter your backend URL",
        tooltip:
          "This is the Gravito backend Url where you want to store your consents. Leave this field if you are not using Gravito's backend capabilities",
        required: false,
        advanced: true,
        default: "",
        xs: 12,
        sm: 12,
      },
      {
        name: "userIdModule",
        type: "checkbox",
        label: (
          <>
            Use prebid user ID module{" "}
            <a
              style={{
                color: "#387d9a",
                textDecoration: "underline",
                fontSize: "11px",
              }}
              href="https://docs.gravito.net/Other_Features/Prebid_User_Id_Module/"
              target="_blank"
            >
              Learn more
            </a>
          </>
        ),
        tooltip:
          "Check this box if you want to use prebid user ID module with your CMP.",
        guiderText:
          "Note: If you are using a wrapper like Livewrapped to initiate Prebid Id modules, you dont need to initiate Gravito's UserId module as part of the SDK.",
        required: false,
        advanced: true,
        default: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "showPixelData",
        type: "checkbox",
        label: "Show Pixel Data",
        tooltip:
          "Check this box If you want to show pixel data in Cookie report layer",
        required: false,
        advanced: true,
        default: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "cookieReportURL",
        type: "text",
        label: "Cookie Report URL",
        placeholder: "Enter your cookie report URL",
        tooltip:
          "This is the URL from where the cookie report data will be fetched.",
        required: false,
        advanced: true,
        default: "",
        xs: 12,
        sm: 12,
      },
      {
        name: "cookieReportVersion",
        type: "hidden",
        default: 2,
      },
      {
        name: "gtmTags",
        type: "gtmTags",
        label: "GTM Tags",
        advanced: true,
        helperText:
          "Only for custom setup. You don't have to worry about these settings.",
        default: [],
      },
      {
        name: "type",
        type: "hidden",
        default: "Light",
      },
      {
        name: "sdkVersion",
        type: "hidden",
        default: 3,
      },
      {
        name: "version",
        type: "hidden",
        default: "bundle_latest_5",
      },
    ],
    core: [
      {
        name: "cookieName",
        type: "text",
        label: "Cookie name",
        placeholder: "Enter your cookie name",
        tooltip:
          "This will be the name of cookie in which you need to store the consents",
        required: false,
        advanced: true,
        default: "GCString",
        xs: 12,
        sm: 6,
      },
      {
        name: "cookieExpiry",
        type: "number",
        label: "Cookie Expiry",
        placeholder: "Enter your cookie expiry",
        tooltip:
          "This will be the expiry of cookie(in days) in which you need to store the consents",
        required: false,
        advanced: true,
        default: 365,
        xs: 12,
        sm: 6,
      },
      {
        name: "languageCode",
        type: "hidden",
        default: "en",
      },
      {
        name: "settingBtnId",
        type: "text",
        label: "Setting button ID",
        placeholder: "Enter your setting button ID",
        tooltip: "It is the ID of element on click of which cmp will shown.",
        required: false,
        advanced: true,
        default: "manageSettings",
        xs: 12,
        sm: 6,
      },
      {
        name: "firstLayerId",
        type: "text",
        label: "Element ID of first layer container",
        placeholder: "Enter your first layer container ID",
        tooltip:
          "This is the ID of container element in which you want to render firstlayer of CMP, if you want use standard CMP layout leave this field empty.",
        required: false,
        advanced: true,
        default: "",
        xs: 12,
        sm: 6,
      },
      {
        name: "secondLayerId",
        type: "text",
        label: "Element ID of second layer container",
        placeholder: "Enter your second layer container ID",
        tooltip:
          "This is the ID of container element in which you want to render secondlayer of CMP, if you want use standard CMP layout leave this field empty.",
        required: false,
        advanced: true,
        default: "",
        xs: 12,
        sm: 6,
      },
      {
        name: "cookieReportLayerId",
        type: "text",
        label: "Element ID of cookie layer container",
        placeholder: "Enter your cookie layer container ID",
        tooltip:
          "This is the ID of container element in which you want to render cookie report layer of CMP, if you want use standard CMP layout leave this field empty.",
        required: false,
        advanced: true,
        default: "",
        xs: 12,
        sm: 6,
      },
      {
        name: "syncEvents",
        type: "multiSelect",
        label: "Sync Events",
        placeholder: "Select your sync events",
        options: [
          "layer2:visible",
          "layer1:opt-in:all",
          "layer2:opt-in:all",
          "layer2:opt-in:selected",
          "layer2:opt-out:all",
          "layer1:opt-out:all",
          "layer2:closed",
          "cmploaded",
          "layer1:visible",
          "opt-in:previously",
          "cmpui:closed",
        ],
        advanced: true,
        tooltip:
          "This are the cmp events on which you want to sync data with backend.If not sure please don't modify this fields.",
        required: false,
        default: [
          "layer1:opt-in:all",
          "layer2:opt-in:all",
          "layer2:opt-in:selected",
          "layer2:opt-out:all",
          "layer1:opt-out:all",
        ],
        xs: 12,
        sm: 12,
      },
      {
        name: "syncAfter",
        type: "number",
        label: "Sync After(in seconds)",
        placeholder: "Enter sync after",
        tooltip:
          "This is the delay in seconds till which backend sync will not occur even after occurence of sync events.",
        required: false,
        advanced: true,
        default: 1800,
        xs: 12,
        sm: 6,
      },
      {
        name: "version",
        type: "number",
        label: "Version",
        placeholder: "Enter version",
        tooltip: "This will be the version number of your config.",
        required: false,
        advanced: true,
        default: 1,
        xs: 12,
        sm: 6,
      },
      {
        name: "useTopDomain",
        type: "checkbox",
        label: "Do you want to write cookie in top domain",
        tooltip: "It is the ID of element on click of which cmp will shown.",
        required: false,
        advanced: true,
        default: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "consents",
        type: "consents",
        label: "Consents",
        advanced: false,
        helperText:
          "Using this section you will be able to configure the consents.",
        required: false,
        default: {
          da: [
            {
              type: "consent",
              id: 1,
              name: "Essentielle cookies",
              description:
                "Essentielle cookies og lignende teknologier implementerer de basale funktioner på hjemmesiden, så som sidenavigation, brug af formularer og funktionalitet i indkøbskurven. Uden disse teknologier fungerer hjemmesiden ikke korrekt.",
              isConsentable: false,
            },
            {
              type: "consent",
              id: 2,
              name: "Funktionelle cookies",
              description:
                "Funktionelle cookies og lignende teknologier gør det muligt at gemme oplysninger, der ændrer måden hjemmesiden vises for dig eller fungerer på, f.eks. dit foretrukne sprog.",
              isConsentable: true,
            },
            {
              type: "consent",
              id: 3,
              name: "Statistiske cookies",
              description:
                "Statistiske cookies og lignende teknologier giver os mulighed for at indsamle oplysninger om, hvordan vores hjemmeside anvendes. Disse oplysninger hjælper os med at forbedre indholdet og brugbarheden af hjemmesiden.",
              isConsentable: true,
            },
            {
              type: "consent",
              id: 4,
              name: "Markedsførings- og reklame-cookies",
              description:
                "Markedsførings-cookies og lignende teknologier bruges til at spore besøgende på tværs af hjemmesiden. Hensigten er at vise annoncer, der er relevante og interessante for dig, og derfor mere værdifulde for os og tredjepartsannoncører.",
              isConsentable: true,
            },
          ],
          en: [
            {
              type: "consent",
              id: 1,
              name: "Essential cookies",
              description:
                "Essential cookies and similar technologies are implementing the basic functions of the website such as page navigation, use of forms and shopping cart functionality. Without these technologies the website will not work properly.",
              isConsentable: false,
            },
            {
              type: "consent",
              id: 2,
              name: "Functional cookies",
              description:
                "Functional cookies and similar technologies make it possible to save information that changes the way the website appears to you or functions, e.g. your preferred language.",
              isConsentable: true,
            },
            {
              type: "consent",
              id: 3,
              name: "Statistical cookies",
              description:
                "Statistical cookies and similar technologies allow us to collect information about how our website is used. This information helps us to improve the content and usability of the website.",
              isConsentable: true,
            },
            {
              type: "consent",
              id: 4,
              name: "Marketing & advertising cookies",
              description:
                "Marketing cookies and similar technologies are used to track visitors across websites. The intention is to display ads that are relevant and interesting to you and thus more valuable for us and third-party advertisers.",
              isConsentable: true,
            },
          ],
          fr: [
            {
              type: "consent",
              id: 1,
              name: "Cookies nécessaires",
              description:
                "Les cookies nécessaires et technologies similaires permettent d'exécuter les fonctionnalités de base du site internet telles que la navigation sur les pages, l'utilisation des formulaires et le panier d'achat. Sans ces technologies, le site internet ne fonctionnera pas correctement.",
              isConsentable: false,
            },
            {
              type: "consent",
              id: 2,
              name: "Cookies fonctionnels",
              description:
                "Les cookies fonctionnels et technologies similaires permettent d'enregistrer les informations qui modifient l'apparence du site internet ou la manière dont il fonctionne (par exemple, la langue que vous avez choisie).",
              isConsentable: true,
            },
            {
              type: "consent",
              id: 3,
              name: "Cookies de statistiques",
              description:
                "Les cookies de statistiques et technologies similaires nous permettent de recueillir des informations sur la façon dont notre site internet est utilisé. Celles-ci nous aident à améliorer le contenu et la convivialité du site internet.",
              isConsentable: true,
            },
            {
              type: "consent",
              id: 4,
              name: "Cookies publicitaires et de marketing",
              description:
                "Marketing cookies and similar technologies are used to track visitors across websites. The intention is to display ads that are relevant and interesting to you and thus more valuable for us and third-party advertisers.",
              isConsentable: true,
            },
          ],
          nl: [
            {
              type: "consent",
              id: 1,
              name: "Noodzakelijke cookies",
              description:
                "Noodzakelijke cookies en soortgelijke technologieën implementeren de basisfuncties van de website, zoals de navigatie op pagina's, het gebruik van formulieren en de functionaliteit van de winkelwagen. Zonder deze technologieën zal de website niet goed werken.",
              isConsentable: false,
            },
            {
              type: "consent",
              id: 2,
              name: "Functionele cookies",
              description:
                "Functionele cookies en vergelijkbare technologieën maken het mogelijk om informatie op te slaan die de manier verandert waarop de website voor jou verschijnt of functioneert, zoals je voorkeurstaal.",
              isConsentable: true,
            },
            {
              type: "consent",
              id: 3,
              name: "Analytische cookies",
              description:
                "Analytische cookies en vergelijkbare technologieën zorgen ervoor dat we informatie kunnen verzamelen over hoe onze website gebruikt wordt. Deze informatie helpt ons de inhoud en bruikbaarheid van de website te verbeteren.",
              isConsentable: true,
            },
            {
              type: "consent",
              id: 4,
              name: "Marketing- & trackingcookies",
              description:
                "Marketingcookies en vergelijkbare technologieën worden gebruikt om bezoekers op verschillende websites te volgen. De bedoeling is om advertenties weer te geven die voor jou relevant en interessant zijn en dus waardevoller zijn voor ons en externe adverteerders.",
              isConsentable: true,
            },
          ],
          fi: [
            {
              type: "consent",
              id: 1,
              name: "Välttämättömät evästeet",
              description:
                "Palvelu käyttää välttämättömiä evästeitä esim. kirjautumistietojen tallentamiseen.",
              isConsentable: false,
            },
            {
              type: "consent",
              id: 2,
              name: "Toiminnalliset evästeet",
              description:
                "Palvelu käyttää toiminnallisia evästeitä esim. asetusten tallentamiseen sovelluksessa, nämä evästeet eivät sisällä henkilötietoja tai -tunnisteita.",
              isConsentable: true,
            },
            {
              type: "consent",
              id: 3,
              name: "Tilastolliset evästeet",
              description:
                "Palvelu käyttää tilastollisia evästeitä seuratakseen miten sivustoa tai sovellusta käytetään, kerätty tieto on anonymisoitua.",
              isConsentable: true,
            },
            {
              type: "consent",
              id: 4,
              name: "Markkinoinnin evästeet",
              description:
                "Palvelu käyttää markkinointiin liittyviä evästeitä, ne voivat seurata käyttäjää eri sivustoilla ja sovelluksissa, mahdollistaen sisällön ja mainonnan paremman kohdentamisen ja käyttökokemuksen optimoinnin.",
              isConsentable: true,
            },
          ],
          nb: [
            {
              type: "consent",
              id: 1,
              name: "Viktige informasjonskapsler",
              description:
                "Viktige informasjonskapsler og lignende teknologi implementerer de grunnleggende funksjonene til nettstedet, som f.eks. navigasjon på sidene, bruk av skjemaer og handlevogn-funksjonalitet. Uten disse teknologiene vil nettstedet ikke fungere slik det skal.",
              isConsentable: false,
            },
            {
              type: "consent",
              id: 2,
              name: "Funksjonelle informasjonskapsler",
              description:
                "Funksjonelle informasjonskapsler og lignende teknologi gjør det mulig å lagre informasjon som endrer hvordan nettstedet ser ut for deg eller fungerer, f.eks. hvilket språk du foretrekker.",
              isConsentable: true,
            },
            {
              type: "consent",
              id: 3,
              name: "Statistiske informasjonskapsler",
              description:
                "Statistiske informasjonskapsler og lignende teknologi gjør at vi kan samle informasjon om hvordan nettstedet vårt brukes. Denne informasjonen hjelper oss med å forbedre innholdet på nettstedet og gjøre det nyttigere og mer brukervennlig.",
              isConsentable: true,
            },
            {
              type: "consent",
              id: 4,
              name: "Informasjonskapsler for markedsføring og annonser",
              description:
                "Informasjonskapsler for markedsføring og lignende teknologi brukes til å spore besøkende på  tvers av nettsteder. Med dette ønsker vi å vise annonser som passer og er interessante for deg, og som slik sett er mer verdifulle for oss og tredjeparts annonsører.",
              isConsentable: true,
            },
          ],
          sv: [
            {
              type: "consent",
              id: 1,
              name: "Nödvändiga",
              description:
                "Nödvändiga cookies och liknande tekniker behövs för de grundläggande funktionerna inom webbplatsen, exempelvis navigering mellan sidor, användning av formulär samt kundvagn. Utan dessa cookies kommer webbplatsen inte att fungera korrekt.",
              isConsentable: false,
            },
            {
              type: "consent",
              id: 2,
              name: "Functionella cookies",
              description:
                "Funktionella cookies och liknande tekniker gör det möjligt att spara information som förändrar webbplatsens utseende och funktionalitet för dig. Det är exempelvis dina inställningar för språk.",
              isConsentable: true,
            },
            {
              type: "consent",
              id: 3,
              name: "Analys och användarupplevelse",
              description:
                "Cookies och liknande tekniker relaterade till analys och användarupplevelse ger oss möjlighet att samla in information om hur vår webbplats används. Denna information hjälper oss förbättra webbplatsens innehåll och funktionalitet.",
              isConsentable: true,
            },
            {
              type: "consent",
              id: 4,
              name: "Marknadsföring",
              description:
                "Marknadsföringscookies och liknande tekniker används för att spåra besökare mellan olika webbplatser. Avsikten är att visa annonser som är relevanta och intressanta för dig, och därmed mer värdefulla för oss och för tredjepartsannonsörer.",
              isConsentable: true,
            },
          ],
          de: [
            {
              type: "consent",
              id: 1,
              name: "Notwendige Cookies",
              description:
                "Notwendige Cookies und \u00e4hnliche Technologien implementieren die Grundfunktionen der Website wie die Seitennavigation, die Verwendung von Formularen und die Warenkorbfunktion. Ohne diese Technologien kann die Website nicht richtig funktionieren.",
              isConsentable: false,
            },
            {
              type: "consent",
              id: 2,
              name: "Funktionale Cookies",
              description:
                "Funktionale Cookies und \u00e4hnliche Technologien erm\u00f6glichen die Speicherung von Informationen, die das Erscheinungsbild oder die Funktionsweise der Website ver\u00e4ndern, z.\u00a0B. Ihre bevorzugte Sprache.",
              isConsentable: true,
            },
            {
              type: "consent",
              id: 3,
              name: "Statistische Cookies",
              description:
                "Statistische Cookies und \u00e4hnliche Technologien erm\u00f6glichen es uns, Informationen dar\u00fcber zu sammeln, wie unsere Website genutzt wird. Diese Informationen helfen uns, den Inhalt und die Benutzerfreundlichkeit der Website zu verbessern.",
              isConsentable: true,
            },
            {
              type: "consent",
              id: 4,
              name: "Marketing- und Werbe-Cookies",
              description:
                "Marketing-Cookies und \u00e4hnliche Technologien werden verwendet, um Besucher auf verschiedenen Websites zu verfolgen. Die Absicht dahinter ist, Anzeigen zu schalten, die f\u00fcr Sie relevant und interessant sind und somit f\u00fcr uns und Drittanbieter wertvoller sind.",
              isConsentable: true,
            },
          ],
          pl: [
            {
              type: "consent",
              id: 1,
              name: "Niezb\u0119dne pliki cookie",
              description:
                "Niezb\u0119dne pliki cookie i podobne technologie umo\u017cliwiaj\u0105 realizacj\u0119 podstawowych funkcji strony, takich jak nawigacja, korzystanie z formularzy i koszyka. Bez tych technologii strona nie b\u0119dzie dzia\u0142a\u0107 poprawnie.",
              isConsentable: false,
            },
            {
              type: "consent",
              id: 2,
              name: "Funkcjonalne pliki cookie",
              description:
                "Funkcjonalne pliki cookie i podobne technologie pozwalaj\u0105 na zapisywanie informacji, kt\u00f3re zmieniaj\u0105 spos\u00f3b prezentacji i dzia\u0142ania strony, np. preferowany j\u0119zyk.",
              isConsentable: true,
            },
            {
              type: "consent",
              id: 3,
              name: "Statystyczne pliki cookie",
              description:
                "Statystyczne pliki cookie i podobne technologii pozwalaj\u0105 nam zbiera\u0107 informacje o tym, jak nasza strona jest u\u017cywana. Te informacje pomagaj\u0105 nam udoskonala\u0107 jej zawarto\u015b\u0107 i u\u017cyteczno\u015b\u0107.",
              isConsentable: true,
            },
            {
              type: "consent",
              id: 4,
              name: "Marketingowe i reklamowe pliki cookie",
              description:
                "Marketingowe pliki cookie i podobne technologie s\u0142u\u017c\u0105 do monitorowania aktywno\u015bci u\u017cytkownik\u00f3w na stronach. Ich celem jest umo\u017cliwienie wy\u015bwietlania trafnych i interesuj\u0105cych reklam, co jest korzystne dla nas i zewn\u0119trznych reklamodawc\u00f3w.",
              isConsentable: true,
            },
          ],
        },
        xs: 12,
        sm: 12,
      },
      {
        name: "GCMConfig",
        type: "GCMConfig",
        label: "GCM-V2 Setup",
        helperText:
          "Using this section you can configure the Google Consent Mode V2. Please select mapping for google required consents.",
        fields: [
          {
            name: "adsConsentId",
            type: "select",
            label: "Ads consent ID",
            placeholder: "Enter ads consent ID",
            tooltip:
              "It is the ID of Ads consent that will be shown on second layer of Gravito CMP.",
            optionsPath: "consents",
            required: false,
            default: 0,
            xs: 12,
            sm: 6,
          },
          {
            name: "adsUserDataConsentId",
            type: "select",
            label: "User data consent ID",
            placeholder: "Enter User Data consent ID",
            tooltip:
              "It is the ID of User Data consent that will be shown on second layer of Gravito CMP.",
            optionsPath: "consents",
            required: false,
            default: 0,
            xs: 12,
            sm: 6,
          },
          {
            name: "adsPersonalizationConsentId",
            type: "select",
            label: "Personalization consent ID",
            placeholder: "Enter Personalization consent ID",
            tooltip:
              "It is the ID of Personalization consent that will be shown on second layer of Gravito CMP.",
            optionsPath: "consents",
            required: false,
            default: 0,
            xs: 12,
            sm: 6,
          },
          {
            name: "analyticsConsentId",
            type: "select",
            label: "Analytics consent ID",
            placeholder: "Enter analytics consent ID",
            tooltip:
              "It is the ID of Analytics consent that will be shown on second layer of Gravito CMP.",
            optionsPath: "consents",
            required: false,
            default: 0,
            xs: 12,
            sm: 6,
          },
          // {
          //   name: "functionalityStorageId",
          //   type: "select",
          //   label: "Functionality Storage consent ID",
          //   placeholder: "Enter functionality storage consent ID",
          //   tooltip:
          //     "It is the ID of Functionality Storage consent that will be shown on second layer of Gravito CMP.",
          //   optionsPath: "consents",
          //   required: false,
          //   default: 0,
          //   xs: 12,
          //   sm: 6,
          // },
          // {
          //   name: "personalizationStorageId",
          //   type: "select",
          //   label: "Personalization Storage consent ID",
          //   placeholder: "Enter Personalization storage consent ID",
          //   tooltip:
          //     "It is the ID of Personalization Storage consent that will be shown on second layer of Gravito CMP.",
          //   optionsPath: "consents",
          //   required: false,
          //   default: 0,
          //   xs: 12,
          //   sm: 6,
          // },
          // {
          //   name: "securityStorageId",
          //   type: "select",
          //   label: "Security Storage consent ID",
          //   placeholder: "Enter Security storage consent ID",
          //   tooltip:
          //     "It is the ID of Security Storage consent that will be shown on second layer of Gravito CMP.",
          //   optionsPath: "consents",
          //   required: false,
          //   default: 0,
          //   xs: 12,
          //   sm: 6,
          // },
        ],
        required: false,
        advanced: false,
        xs: 12,
        sm: 12,
      },
    ],
    text: [
      {
        layerName: "First Layer",
        fields: [
          {
            name: "firstLayer.title",
            type: "text",
            label: "Title",
            placeholder: "Enter title",
            tooltip:
              "This will be the title of your CMP banner on first layer.",
            required: false,
            default: {
              da: "Vi skal bruge dit samtykke, for at give en personliggjort oplevelse",
              en: "We need your consent to provide personalized experience",
              fr: "Nous avons besoin de votre consentement pour vous proposer une expérience personnalisée.",
              nl: "We hebben jouw toestemming nodig om een gepersonaliseerde ervaring te bieden",
              fi: "Evästeasetukset",
              nb: "Vi må ha samtykke fra deg for å gi en personlig tilpasset opplevelse",
              sv: "Cookieinställningar",
              de: "Wir benötigen Ihre Zustimmung, um Ihnen ein personalisiertes Erlebnis zu bieten",
              pl: "Potrzebujemy Twojej zgody, aby zapewnić Ci spersonalizowane doświadczenie",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "firstLayer.checkBoxLabels.consent",
            type: "text",
            label: "Checkbox labels for consents",
            placeholder: "Enter checkbox labels for consents",
            tooltip:
              "This will be the label text for the consents on first layer.(Translation of word consent)",
            required: false,
            default: {
              da: "Samtykke",
              en: "Consent",
              fr: "Consent",
              nl: "Toestemming",
              fi: "Hyväksy",
              nb: "Samtykke",
              sv: "Medgivande",
              de: "Zustimmung",
              pl: "Zgadzam się",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "firstLayer.introductionText",
            type: "text",
            label: "Introduction text",
            placeholder: "Enter introduction text",
            multiline: true,
            tooltip:
              "This will be the introductions text of your CMP banner on first layer.",
            required: false,
            default: {
              da: "Vi og vores tredjepartsforhandlere indsamler personlige data (f.eks. IP-adresse, enhedsidentifikator) gennem brugen af cookies og andre tekniske metoder, som lagrer og tilgår data på din enhed, for at give den bedste brugeroplevelse og vise målrettet indhold og annoncer.",
              en: "We and our third-party vendors are collecting personal data (e.g. IP address, device identifier) through the use of cookies and other technical methods which are storing and accessing data on your device to provide the best user experience and show targetted content and advertising.",
              fr: "Nous et nos fournisseurs tiers recueillons des données personnelles (par ex., l'adresse IP, l'identifiant de l'appareil, etc.) en utilisant des cookies et d'autres méthodes techniques qui stockent des données sur votre appareil et y accèdent afin de vous proposer une expérience utilisateur optimale, et vous montrer des contenus et publicités ciblés.",
              nl: "Wij en onze externe leveranciers verzamelen persoonlijke gegevens (zoals IP-adres, apparaat-ID) door het gebruik van cookies en andere technische methoden die gegevens van jouw apparaat opslaan en bekijken om de beste gebruikerservaring te bieden en gerichte inhoud en advertenties weer te geven.",
              fi: "Käytämme evästeitä ja vastaavia tekniikoita, jotta voimme parantaa ja räätälöidä käyttökokemusta ja näyttää mainoksia. Napsauttamalla Hyväksy kaikki hyväksyt evästeiden ja vastaavien tekniikoiden käytön.",
              nb: "Vi og tredjepartsannonsørene våre samler inn personopplysninger (f.eks. IP-adresse, identifikator for enheten din) gjennom bruk av informasjonskapsler og andre tekniske metoder som lagrer og går inn på data på enheten din. Dette for å gi en best mulig brukeropplevelse og vise målrettet innhold og annonser.",
              sv: "Vi använder cookies och liknande tekniker för att förbättra och anpassa din upplevelse samt för att visa annonser. Genom att klicka på Acceptera alla så godkänner du användandet av cookies och liknande tekniker.Du kan ändra dina inställningar när som helst genom att välja Cookieinställningar längst ner på webbplatsen.",
              de: "Wir und die Drittanbieter, mit denen wir zusammenarbeiten, erfassen personenbezogene Daten (z. B. IP-Adresse, Gerätekennung) durch die Verwendung von Cookies und anderen technischen Methoden, die Daten auf Ihrem Gerät speichern und darauf zugreifen, um Ihnen ein optimales Nutzererlebnis zu bieten und zielgerichtete Inhalte und Werbung zu zeigen.",
              pl: "My i nasi zewnętrzni dostawcy zbieramy dane osobowe (np. adres IP, identyfikator urządzenia) za pomocą plików cookie i innych metod technicznych, które przechowują i uzyskują dostęp do danych na Twoim urządzeniu, aby zapewnić Ci najlepsze wrażenia użytkownika oraz wyświetlać spersonalizowane treści i reklamy.",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "firstLayer.legalFooter",
            type: "text",
            label: "Legal Footer text",
            placeholder: "Enter legal footer",
            tooltip:
              "This will be the title of your CMP banner on first layer.",
            required: false,
            default: {
              da: "Ved at acceptere, tillader du behandling af dataene i tjenesten. Afvisning kan påvirke brugeroplevelsen.",
              en: "By accepting, you are allowing data processing within the service, rejection can affect the user experience.",
              fr: "En acceptant, vous autorisez le traitement des données dans le cadre du service. Le refus peut nuire à l'expérience utilisateur.",
              nl: "Door te accepteren, sta je gegevensverwerking binnen de dienst toe, afwijzing kan de gebruikerservaring beïnvloeden.",
              fi: "Hyväksymällä sallit tietojesi käsittelyn. Suostumuksesi koskee tätä palvelua, hyväksymättä jättäminen voi vaikuttaa asiakaskokemukseesi.",
              nb: "Ved å godta tillater du databehandling innenfor tjenesten. Avslag kan påvirke brukeropplevelsen.",
              sv: "Genom att acceptera samtycker du till behandlingen av dina uppgifter. Ditt samtycke gäller för denna tjänst, underlåtenhet att göra det kan påverka din kundupplevelse.",
              de: "Durch Akzeptieren erlauben Sie die Datenverarbeitung im Rahmen des Dienstes. Im Falle einer Ablehnung kann jedoch das Nutzererlebnis beeinträchtigt werden.",
              pl: "Akceptując, wyrażasz zgodę na przetwarzanie danych w obrębie tego serwisu. Odmowa może negatywnie wpłynąć na wrażenia użytkownika.",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "firstLayer.privacyPolicyUrl",
            type: "text",
            label: "Privacy Policy URL",
            placeholder: "Enter privacy policy URL",
            tooltip: "This will be the privacy policy URL on first layer.",
            required: false,
            default: "",
            xs: 12,
            sm: 6,
          },
          {
            name: "firstLayer.privacyLabel",
            type: "text",
            label: "Privacy Policy URL Label",
            placeholder: "Enter privacy policy URL label",
            tooltip:
              "This will be the label for your privacy policy URL on first layer",
            required: false,
            default: {
              da: "Privatlivspolitik",
              en: "Privacy Policy",
              fr: "Privacy Policy",
              nl: "Privacybeleid",
              fi: "Tietosuoja",
              nb: "Personvernerklæring",
              sv: "Cookiepolicy",
              de: "Datenschutzerklärung",
              pl: "Polityka prywatności",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "firstLayer.actions[0]",
            type: "text",
            label: "Accept All Label",
            placeholder: "Enter accept all label",
            tooltip:
              "This will be the label for accept all button on first layer",
            required: false,
            default: {
              da: "Accepter alle",
              en: "Accept all",
              fr: "Tout accepter",
              nl: "Alles accepteren",
              fi: "Hyväksy kaikki",
              nb: "Godta alle",
              sv: "Acceptera alla",
              de: "Alle akzeptieren",
              pl: "Akceptuj wszystkie",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "firstLayer.actions[1]",
            type: "text",
            label: "Settings Label",
            placeholder: "Enter settings label",
            tooltip:
              "This will be the label for settings button on first layer",
            required: false,
            default: {
              da: "Indstillinger",
              en: "Settings",
              fr: "Paramètres",
              nl: "Instellingen",
              fi: "Asetukset",
              nb: "Innstillinger",
              sv: "Hantera inställningar",
              de: "Einstellungen",
              pl: "Ustawienia",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "firstLayer.actions[2]",
            type: "text",
            label: "Reject All Label",
            placeholder: "Enter reject all label",
            tooltip:
              "This will be the label for reject all button on first layer",
            required: false,
            default: {
              da: "",
              en: "",
              fr: "",
              nl: "",
              fi: "",
              nb: "",
              sv: "",
              de: "",
              pl: "",
            },
            xs: 12,
            sm: 12,
          },
        ],
      },
      {
        layerName: "Second Layer",
        fields: [
          {
            name: "secondLayer.title",
            type: "text",
            label: "Title",
            placeholder: "Enter title",
            tooltip:
              "This will be the title of your CMP banner on second layer.",
            required: false,
            default: {
              da: "Gravito Settings",
              en: "Gravito Settings",
              fr: "Paramètres en matière de consentement",
              nl: "Gravito Settings",
              fi: "Asetukset",
              nb: "Gravito Settings",
              sv: "Cookieinställningar",
              de: "Zustimmungseinstellungen",
              pl: "Ustawienia zgód",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "secondLayer.checkBoxLabels.consent",
            type: "text",
            label: "Checkbox labels for consents",
            placeholder: "Enter checkbox labels for consents",
            tooltip:
              "This will be the label text for the consents on second layer.(Translation of word consent)",
            required: false,
            default: {
              da: "Samtykke",
              en: "Consent",
              fr: "Consent",
              nl: "Toestemming",
              fi: "Hyväksy",
              nb: "Samtykke",
              sv: "Medgivande",
              de: "Zustimmung",
              pl: "Zgadzam się",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "secondLayer.introductionText",
            type: "text",
            label: "Introduction text",
            placeholder: "Enter introduction text",
            multiline: true,
            tooltip:
              "This will be the introductions text of your CMP banner on second layer.",
            required: false,
            default: {
              da: "Vælg på listen over følgende formål, for at hjælpe os med bedre at tjene dig.",
              en: "Please select from the following list of purposes to help us serve you better.",
              fr: "Veuillez effectuer votre sélection dans la liste d'objectifs suivante afin de nous aider à mieux vous servir.",
              nl: "Maak een keuze uit de volgende lijst met doeleinden, zodat wij je beter van dienst kunnen zijn.",
              fi: 'Voit valita listatuista käyttötarkoituksista ne, jotka haluat hyväksyä ja tallentaa asetukset klikkaamalla "Hyväksy valitut".',
              nb: "Vennligst velg alternativer i følgende liste over formål, slik at vi kan gi deg en bedre tjeneste.",
              sv: "Välj de syften för vilka du godkänner användandet av cookies och liknande tekniker. Du kan ändra dina inställningar när som helst genom att välja Cookieinställningar längst ner på webbplatsen.",
              de: "Bitte treffen Sie eine Auswahl in der folgenden Liste, damit wir Ihnen einen besseren Service bieten können.",
              pl: "Wybierz cele z listy poniżej, abyśmy mogli lepiej spełnić Twoje oczekiwania.",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "secondLayer.privacyPolicyUrl",
            type: "text",
            label: "Privacy Policy URL",
            placeholder: "Enter privacy policy URL",
            tooltip: "This will be the privacy policy URL on second layer.",
            required: false,
            default: "",
            xs: 12,
            sm: 6,
          },
          {
            name: "secondLayer.privacyLabel",
            type: "text",
            label: "Privacy Policy URL Label",
            placeholder: "Enter privacy policy URL label",
            tooltip:
              "This will be the label for your privacy policy URL on first layer",
            required: false,
            default: {
              da: "Privatlivspolitik",
              en: "Privacy Policy",
              fr: "Politique de confidentialité",
              nl: "Privacybeleid",
              fi: "Tietosuoja",
              nb: "Personvernerklæring",
              sv: "Cookiepolicy",
              de: "Datenschutzerklärung",
              pl: "Polityka prywatności",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "secondLayer.actions[0]",
            type: "text",
            label: "Accept All Label",
            placeholder: "Enter accept all label",
            tooltip:
              "This will be the label for accept all button on second layer",
            required: false,
            default: {
              da: "Accepter alle",
              en: "Accept all",
              fr: "Tout accepter",
              nl: "Alles accepteren",
              fi: "Hyväksy kaikki",
              nb: "Godta alle",
              sv: "Acceptera alla",
              de: "Alle akzeptieren",
              pl: "Akceptuj wszystkie",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "secondLayer.actions[1]",
            type: "text",
            label: "Accept Selected Label",
            placeholder: "Enter accept selected label",
            tooltip:
              "This will be the label for accept selected button on second layer",
            required: false,
            default: {
              da: "Afvis alle",
              en: "Accept selected",
              fr: "Accept selected",
              nl: "Geselecteerde opties accepteren",
              fi: "Hyväksy valitut",
              nb: "Godta valgte",
              sv: "Acceptera valda",
              de: "Auswahl akzeptieren",
              pl: "Akceptuj wybrane",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "secondLayer.actions[2]",
            type: "text",
            label: "Reject All Label",
            placeholder: "Enter reject all label",
            tooltip:
              "This will be the label for reject all button on second layer",
            required: false,
            default: {
              da: "Afvis alle",
              en: "Reject all",
              fr: "Tout refuser",
              nl: "Alles afwijzen",
              fi: "Estä kaikki",
              nb: "Avvis alle",
              sv: "Avvisa alla",
              de: "Alle akzeptieren",
              pl: "Odrzuć wszystkie",
            },
            xs: 12,
            sm: 12,
          },
        ],
      },
      {
        layerName: "Confirmation Layer",
        helperText:
          "If you choose to enable confirmation modals (checkbox under 'Appearance') for double-checking user choices on the second layer, then this is the text that will appear on the modal element.",
        fields: [
          {
            name: "confirmationLayer.confirmationForUncheck.heading",
            type: "text",
            label: "Heading for confirmation for uncheck consent",
            placeholder: "Enter heading for confirmation for uncheck consent",
            tooltip:
              "This will be heading of confirmation modal which will appear on unchecking the consent.",
            required: false,
            default: {
              da: "Are you sure you want to disable?",
              en: "Are you sure you want to disable?",
              fr: "Are you sure you want to disable?",
              nl: "Weet je zeker dat je dit wilt uitschakelen?",
              fi: "Oletko varma?",
              nb: "Er du sikker på at du vil slå av?",
              sv: "Är du säker?",
              de: "Sind Sie sicher, dass Sie die Option deaktivieren möchten?",
              pl: "Czy na pewno chcesz wyłączyć?",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "confirmationLayer.confirmationForUncheck.paragraphs[0]",
            type: "text",
            label: "Paragraph for confirmation for uncheck consent",
            placeholder: "Enter paragraph for confirmation for uncheck consent",
            multiline: true,
            tooltip:
              "This will be paragraph of confirmation modal which will appear on unchecking the consent.",
            required: false,
            default: {
              da: "These cookies or other technical methods are important so that we can provide you with a better and more personal user experience.",
              en: "These cookies or other technical methods are important so that we can provide you with a better and more personal user experience.",
              fr: "These cookies or other technical methods are important so that we can provide you with a better and more personal user experience.",
              nl: "Deze cookies en andere technische methodes zijn belangrijk zodat we je een betere en meer persoonlijke gebruikservaring kunnen bieden.",
              fi: "Oletko varma että haluat estää kaikki, valinta voi vaikuttaa käyttökokemukseesi?",
              nb: "Disse informasjonskapslene eller andre tekniske metodene er viktige for at vi skal kunne gi deg en bedre og mer personlig brukeropplevelse.",
              sv: "Dessa cookies, identifierare och tekniker används för att optimera din användarupplevelse, och stänger du av dessa kan det påverka din upplevelse.",
              de: "Diese Cookies oder andere technische Methoden sind wichtig, damit wir Ihnen ein besseres und persönlicheres Nutzererlebnis bieten können.",
              pl: "Te pliki cookie lub inne metody techniczne są ważne, abyśmy mogli zapewnić Ci lepsze i bardziej spersonalizowane wrażenia użytkownika.",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "confirmationLayer.confirmationForAcceptSelected.heading",
            type: "text",
            label:
              "Heading for confirmation for Accept Selected uncheck consent",
            placeholder:
              "Enter heading for confirmation for Accept Selected uncheck consent",
            tooltip:
              "This will be heading of confirmation modal which will appears when user clicks on 'Accept Selected' button.",
            required: false,
            default: {
              da: "Er du sikker på, at du vil deaktivere?",
              en: "Are you sure you want to disable?",
              fr: "Êtes-vous sûr(e) de vouloir désactiver les cookies ?",
              nl: "Weet je zeker dat je dit wilt uitschakelen?",
              fi: "Oletko varma?",
              nb: "Er du sikker på at du vil slå av?",
              sv: "Är du säker?",
              de: "Sind Sie sicher, dass Sie die Option deaktivieren möchten?",
              pl: "Czy na pewno chcesz wyłączyć?",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "confirmationLayer.confirmationForAcceptSelected.paragraphs[0]",
            type: "text",
            label:
              "Paragraph for confirmation for Accept Selected uncheck consent",
            placeholder:
              "Enter paragraph for confirmation for Accept Selected uncheck consent",
            multiline: true,
            tooltip:
              "This will be paragraph of confirmation modal which will appear when user clicks on 'Accept Selected' button.",
            required: false,
            default: {
              da: "Cookies eller andre tekniske metoder er vigtige for, at vi kan give dig en bedre og mere personlig oplevelse. Uden cookies eller andre tekniske metoder, bliver vores mulighed for at udvikle vores tjenester baseret på dine præferencer mere besværlig, nogen funktioner kan være blokeret og din brugeroplevelse kan blive forringet.",
              en: "Cookies and other technical methods are important so that we can provide you with a better and more personal user experience. Without cookies or other technical methods, our ability to develop our services based on your preferences becomes more difficult, some features may be blocked, and your user experience may deteriorate.",
              fr: "Les cookies ou autres méthodes techniques sont importants pour que nous puissions vous proposer une expérience utilisateur meilleure et plus personnelle. Sans cookies ou autres méthodes techniques, il nous est plus difficile de développer nos services en fonction de vos préférences, car certaines fonctionnalités peuvent être bloquées, et votre expérience utilisateur peut en pâtir.",
              nl: "Cookies en andere technische methodes zijn belangrijk zodat we je een betere en meer persoonlijke gebruikservaring kunnen bieden. Zonder cookies of andere technische methoden wordt het moeilijker voor ons om onze diensten te ontwikkelen op basis van jouw voorkeuren, kunnen sommige functies worden geblokkeerd en kan je gebruikerservaring verslechteren.",
              fi: "Oletko varma että haluat estää kaikki, valinta voi vaikuttaa käyttökokemukseesi?",
              nb: "Informasjonskapsler og andre tekniske metoder er viktige for at vi skal kunne gi deg en bedre og mer personlig brukeropplevelse. Uten informasjonskapsler eller andre tekniske metoder blir det vanskeligere for oss å utvikle tjenestene våre basert på dine ønsker, noen funksjoner kan bli sperret og brukeropplevelsen din kan bli dårligere.",
              sv: "Dessa cookies, identifierare och tekniker används för att optimera din användarupplevelse, och stänger du av dessa kan det påverka din upplevelse.",
              de: "Cookies und andere technische Methoden sind wichtig, damit wir Ihnen ein besseres und persönlicheres Benutzererlebnis bieten können.Ohne Cookies oder andere technische Methoden fällt es uns schwerer, unsere Dienste auf der Grundlage Ihrer Präferenzen weiterzuentwickeln, einige Funktionen können blockiert werden, und Ihr Nutzererlebnis kann sich verschlechtern.",
              pl: "Pliki cookie i inne metody techniczne s\u0105 wa\u017cne, aby\u015bmy mogli zapewni\u0107 Ci lepsze i bardziej spersonalizowane wra\u017cenia u\u017cytkownika. Bez plik\u00f3w cookie lub innych metod technicznych nasza mo\u017cliwo\u015b\u0107 rozwijania us\u0142ug zgodnie z Twoimi preferencjami staje si\u0119 trudniejsza, niekt\u00f3re funkcje mog\u0105 zosta\u0107 zablokowane, a Twoje wra\u017cenia u\u017cytkownika mog\u0105 si\u0119 pogorszy\u0107.",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "confirmationLayer.actions[0]",
            type: "text",
            label: "Cancel Label",
            placeholder: "Enter cancel label",
            tooltip:
              "This will be the label for cancel button on confirmation modal",
            required: false,
            default: {
              da: "Annuller",
              en: "Cancel",
              fr: "Annuler",
              nl: "Annuleren",
              fi: "Peruuta",
              nb: "Avbryt",
              sv: "Avbryt",
              de: "Abbrechen",
              pl: "Anuluj",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "confirmationLayer.actions[1]",
            type: "text",
            label: "Yes Label",
            placeholder: "Enter yes label",
            tooltip:
              "This will be the label for yes button on confirmation modal",
            required: false,
            default: {
              da: "Ja",
              en: "Yes",
              fr: "Oui",
              nl: "Ja",
              fi: "Kyllä",
              nb: "Ja",
              sv: "Ja",
              de: "Ja",
              pl: "Tak",
            },
            xs: 12,
            sm: 6,
          },
        ],
      },
      {
        layerName: "Cookie Report Layer",
        fields: [
          {
            name: "cookieReportLayer.buttonTitle",
            type: "text",
            label: "Button Title",
            placeholder: "Enter button title",
            tooltip:
              "Title for button which will be used for opening cookie report layer.",
            required: false,
            default: {
              da: "Cookie-rapport",
              en: "Cookie Report",
              fr: "Cookie Report",
              nl: "Cookierapport",
              fi: "Evästeraportti",
              nb: "Informasjonskapsel-rapport",
              sv: "Cookie rapport",
              de: "Cookie-Bericht",
              pl: "Raport o plikach cookie",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.heading",
            type: "text",
            label: "Heading",
            placeholder: "Enter heading",
            tooltip:
              "Text that should be used as heading for cookie report layer.",
            required: false,
            default: {
              da: "Cookie Reports layer header",
              en: "Cookie Reports layer header",
              fr: "Rapport en matière de cookies",
              nl: "Cookie Reports layer header",
              fi: "Sivuston käyttämät evästeet",
              nb: "Cookie Reports layer header",
              sv: "Webplatsen använder följande cookies",
              de: "Cookie-Bericht",
              pl: "Raport o plikach cookie",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.introductionText",
            type: "text",
            label: "Introduction Text",
            placeholder: "Enter introduction text",
            multiline: true,
            tooltip:
              "Text that should be used as introduction text for cookie report layer.",
            required: false,
            default: {
              da: "Cookies er små tekstfiler, som hjemmesider kan bruge til at gøre brugeroplevelsen mere effektiv. Vi bruger cookies på hjemmesiden. Vi bruger cookies på denne hjemmeside til at forbedre brugeroplevelsen, og til bedre at tjene dig. Baseret på vores scanning er dette de cookies, der bliver anvendt, baseret på de præferencer du har indstillet.",
              en: "Cookies are small text files that websites can use to make the user experience more efficient. We use cookies on the website. We use cookies on this website to improve the visitor experience and to better serve you. Based on our scan, this is how the cookies that will be used based on the preference set by you.",
              fr: "Les cookies sont de petits fichiers texte que les sites internet peuvent utiliser pour améliorer l'expérience utilisateur. Nous utilisons des cookies sur le site internet. Nous en utilisons sur celui-ci pour améliorer l'expérience utilisateur et pour mieux vous servir. Sur la base de notre analyse, voici comment les cookies seront utilisés en fonction des préférences que vous avez définies.",
              nl: "Cookies zijn kleine tekstbestanden die websites kunnen gebruiken om de gebruikerservaring efficiënter te maken. Wij gebruiken cookies op de website. Wij gebruiken cookies op deze website om de bezoekerservaring te verbeteren en je beter van dienst te kunnen zijn. Op basis van onze scan zijn dit de cookies die gebruikt zullen worden op basis van de door jouw ingestelde voorkeur.",
              fi: "Evästeitä käytetään sivuston teknisiin toimintoihin, käyttökokemuksen optimointiin ja käyttäjien seurantaan. Tämä raportti sisältää tiedot evästeistä joita sivusto käyttää joko teknisiin käyttötarkoituksiin (ennen suostumusta) ja evästeet ovat luonteeltaan pakollisia sivuston toiminnon kannalta, tai evästeitä käytetään suostumuksen jälkeen (käyttäjä hyväksyy kaikki käyttötarkoitukset). Riippuen suostumuksistasi kaikkia listattuja evästeitä ei välttämättä aseteta selaimeesi.",
              nb: "Informasjonskapsler er små tekstfiler som nettsteder kan bruke for å gjøre brukeropplevelsen mer effektiv. Vi bruker informasjonskapsler på nettstedet. Vi bruker informasjonskapsler på dette nettstedet for å gjøre opplevelsen bedre for besøkende og gi deg en bedre tjeneste. Basert på vår skanning er det slik informasjonskapslene kommer til å bli brukt, basert på innstillingene du har gjort.",
              sv: "Webplatsen använder cookien för tekniska skäl för att optimera användarupplevelsen och följa användaren. Denna rapport innehåller information om cookien som används antingen för tekniska skäl (före samtycken)och är oblikatoriska för att webplatsen kan fungera och om cookien som används efter samtycken(användaren godkänner allt). Beroende på dina samtycken används inte alla listade cookien nödvändigtvis på din webläsare.",
              de: "Cookies sind kleine Textdateien, die von Websites verwendet werden können, um das Nutzererlebnis effizienter zu gestalten. Wir verwenden Cookies auf dieser Webseite, um das Besuchererlebnis zu verbessern und um Ihnen einen besseren Service zu bieten. Basierend auf unserem Scan werden die Cookies auf der Grundlage der von Ihnen eingestellten Präferenzen wie folgt verwendet.",
              pl: "Pliki cookie to małe pliki tekstowe, które strony internetowe mogą wykorzystywać, aby uczynić korzystanie z nich bardziej efektywnym. Używamy plików cookie na tej stronie w celu poprawy doświadczeń odwiedzających i lepszego dostosowania się do ich potrzeb. Na podstawie naszego skanowania przedstawiamy sposób wykorzystania plików cookie zgodnie z wybranymi przez Ciebie preferencjami.",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "cookieReportLayer.beforeAcceptHeader",
            type: "text",
            label: "Before accepting data heading",
            placeholder: "Enter heading",
            tooltip:
              "Text that should be used as Heading for the section cookie report layer which will be showing data before Accepting the consent.",
            required: false,
            default: {
              da: "Før accept",
              en: "Before Accepting",
              fr: "Avant d'accepter",
              nl: "Voor het accepteren",
              fi: "Evästeet ennen suostumusta (pakolliset ja toiminnalliset evästeet)",
              nb: "Før du aksepterer",
              sv: "Cookien före samtycken (nödvändiga och funktionella cookien)",
              de: "Vor der Annahme",
              pl: "Przed akceptacją",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.afterAcceptHeader",
            type: "text",
            label: "After accepting data heading",
            placeholder: "Enter heading",
            tooltip:
              "Text that should be used as heading for the section cookie report layer which will be showing data after Accepting the consent.",
            required: false,
            default: {
              da: "Efter accept",
              en: "After Accepting",
              fr: "Après avoir accepté",
              nl: "Na het accepteren",
              fi: "Evästeet suostumushyväksynnän jälkeen",
              nb: "Etter at du har akseptert",
              sv: "Cookien efter samtycken",
              de: "Nach der Annahme",
              pl: "Po akceptacji",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.nameHeader",
            type: "text",
            label: "Header for Name",
            placeholder: "Enter header for name",
            tooltip:
              "This text will be used as a header for name coloumn in cookie report layer.",
            required: false,
            default: {
              da: "Navn",
              en: "Name",
              fr: "Nom",
              nl: "Naam",
              fi: "Nimi",
              nb: "Navn",
              sv: "Namn",
              de: "Name",
              pl: "Nazwa",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.domainHeader",
            type: "text",
            label: "Header for domain",
            placeholder: "Enter header for domain",
            tooltip:
              "This text will be used as a header for domain coloumn in cookie report layer.",
            required: false,
            default: {
              da: "Domæne",
              en: "Domain",
              fr: "Domaine",
              nl: "Domein",
              fi: "Domain-nimi",
              nb: "Domene",
              sv: "Domän",
              de: "Domäne",
              pl: "Domena",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.descriptionHeader",
            type: "text",
            label: "Header for description",
            placeholder: "Enter header for description",
            tooltip:
              "This text will be used as a header for description coloumn in cookie report layer.",
            required: false,
            default: {
              da: "Beskrivelse",
              en: "Description",
              fr: "Description",
              nl: "Omschrijving",
              fi: "Kuvaus",
              nb: "Beskrivelse",
              sv: "Syfte",
              de: "Beschreibung",
              pl: "Opis",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.cookieTypeHeader",
            type: "text",
            label: "Header for cookie type",
            placeholder: "Enter header for cookie type",
            tooltip:
              "This text will be used as a header for cookie type coloumn in cookie report layer.",
            required: false,
            default: {
              da: "Cookie-type",
              en: "Cookie Type",
              fr: "Type de cookies",
              nl: "Cookietype",
              fi: "Tyyppi",
              nb: "Cype informasjonskapsel",
              sv: "Typ",
              de: "Cookie-Typ",
              pl: "Rodzaj pliku cookie",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.expiryHeader",
            type: "text",
            label: "Header for expiry",
            placeholder: "Enter header for expiry",
            tooltip:
              "This text will be used as a header for expiry coloumn in cookie report layer.",
            required: false,
            default: {
              da: "EUdløber om dage",
              en: "Expiry in days",
              fr: "Expiration en jours",
              nl: "Vervaldatum in dagen",
              fi: "Elinikä",
              nb: "Utløper om dager",
              sv: "Livslängd",
              de: "Verfall in Tagen",
              pl: "Ważność w dniach",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.httpOnlyHeader",
            type: "text",
            label: "Header for httpOnly",
            placeholder: "Enter header for httpOnly",
            tooltip:
              "This text will be used as a header for httpOnly coloumn in cookie report layer.",
            required: false,
            default: {
              da: "Udløber om dage",
              en: "Http Only",
              fr: "Http uniquement",
              nl: "Alleen http",
              fi: "Palvelineväste",
              nb: "Kun Http",
              sv: "Server cookie",
              de: "Nur HTTP",
              pl: "Tylko HTTP",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.size",
            type: "text",
            label: "Header for size",
            placeholder: "Enter header for size",
            tooltip:
              "This text will be used as a header for size coloumn in cookie report layer.",
            required: false,
            default: {
              da: "Størrelse",
              en: "Size",
              fr: "Taille",
              nl: "Grootte",
              fi: "Koko",
              nb: "Størrelse",
              sv: "Storlek",
              de: "Größe",
              pl: "Rozmiar",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.pixelRequestHeader",
            type: "text",
            label: "Header for pixel request",
            placeholder: "Enter header for pixel request",
            tooltip:
              "This text will be used as a header for pixel request coloumn in cookie report layer.",
            required: false,
            default: {
              da: "Pixelanmodninger",
              en: "Pixel Requests",
              fr: "Demandes de pixel",
              nl: "Pixelverzoeken",
              fi: "Pikselipyynnöt",
              nb: "Pixelforespørsler",
              sv: "Pixelbegäran",
              de: "Pixelanfragen",
              pl: "Żądania pikseli",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.noCookieDataMessage",
            type: "text",
            label: "No cookie data message",
            placeholder: "Enter no cookie data message",
            tooltip:
              "This text will be used as text when their is no cookie report data available.",
            required: false,
            default: {
              da: "Ingen scanningsresultater tilgængelige",
              en: "No scan results available",
              fr: "Aucun résultat d'analyse disponible",
              nl: "Geen scanresultaten beschikbaar",
              fi: "Ei evästeskannauksen tuloksia saatavilla.",
              nb: "Ingen skanningresultater tilgjengelige",
              sv: "Inga cookie-scan resultat till hands.",
              de: "Keine Scanergebnisse verfügbar",
              pl: "Brak dostępnych wyników skanowania",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "cookieReportLayer.noCookieDataBeforeAcceptingMsg",
            type: "text",
            label: "No data message for before accepting section",
            placeholder: "Enter no cookie data message",
            tooltip:
              "This text will be used as text when their is no cookie report data available for  before accepting section.",
            required: false,
            default: {
              da: "Ingen cookies blev fundet før accept af samtykke på dette domæne.",
              en: "No cookies were found before Accepting the consents on this domain.",
              fr: "Aucun cookie n'a été trouvé avant d'accorder les consentements sur ce domaine.",
              nl: "Er zijn geen cookies gevonden voor het Accepteren van de toestemmingen op dit domein.",
              fi: "Evästeitä ei löytynyt ennen suostumushyväksyntää.",
              nb: "Ingen informasjonskapsler ble funnet før samtykkene på dette domenet ble Godtatt.",
              sv: "Inga cookien hittades före samtycken.",
              de: "Es wurden keine Cookies gefunden, bevor Sie die Zustimmungen für diese Domain akzeptiert haben.",
              pl: "Nie wykryto plików cookie przed zaakceptowaniem zgód na tej domenie.",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "cookieReportLayer.noCookieDataAfterAcceptingMsg",
            type: "text",
            label: "No data message for After accepting section",
            placeholder: "Enter no cookie data message",
            tooltip:
              "This text will be used as text when their is no cookie report data available for after accepting section.",
            required: false,
            default: {
              da: "No cookies were found after Accepting the consents on this domain.",
              en: "No cookies were found after Accepting the consents on this domain.",
              fr: "Aucun cookie n'a été trouvé après avoir accordé les consentements sur ce domaine.",
              nl: "No cookies were found after Accepting the consents on this domain.",
              fi: "Evästeitä ei löytynyt suostumushyväksynnän jälkeen.",
              nb: "No cookies were found after Accepting the consents on this domain ",
              sv: "Inga cookien hittades efter samtycken.",
              de: "Es wurden keine Cookies gefunden, nachdem Sie die Zustimmungen für diese Domain akzeptiert haben.",
              pl: "Nie wykryto plików cookie po wyrażeniem zgód na tej domenie.",
            },
            xs: 12,
            sm: 12,
          },
        ],
      },
    ],
    style: [
      {
        name: "useCustomCss",
        type: "checkbox",
        label: "Do you want to use externally hosted CSS",
        tooltip:
          "Check this if you want to use externally hosted CSS with your CMP.If you click on this checkbox then you will have to make sure you add the custom CSS to DOM before loading the CMP.",
        guiderText:
          "Note: Preview may not work properly as the css is hosted elsewhere.",
        required: false,
        advanced: true,
        default: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "customCSS",
        type: "customCSS",
        label: "Custom CSS",
        placeholder: "Enter your custom CSS",
        multiline: true,
        minRows: 4,
        advanced: true,
        tooltip: "Use this text area to add custom CSS to CMP.",
        required: false,
        default:
          "/* Add CSS Here... */.gravitoLightCMP-layer1-modal {}.gravitoLightCMP-layer1-content {}.gravitoLightCMP-layer1-title {}.gravitoLightCMP-layer1-introductionText {}.gravitoLightCMP-layer1-legalFooter {}.gravitoLightCMP-layer1-footer {}.gravitoCMP-header-logo-div {}.gravitoLightCMP-layer1-privacyPolicy {}.gravitoLightCMP-layer1-actions-div {}.gravitoLightCMP-layer1-actions-settings {}.gravitoLightCMP-layer1-actions-accept-all {}.gravitoLightCMP-layer1-actions-reject-all {}.gravitoLightCMP-layer2-modal {}.gravitoLightCMP-layer2-title {}.gravitoLightCMP-layer2-close-button {}.gravitoLightCMP-layer2-content {}.gravitoLightCMP-layer2-introductionText {}.gravitoLightCMP-layer2-consentable-items-div {}.gravitoLightCMP-accordion-div {}.gravitoLightCMP-accordion-header {}.gravitoLightCMP-accordion-content {}.gravitoLightCMP-layer2-footer {}.gravitoLightCMP-layer2-actions-div {}.gravitoLightCMP-layer2-actions-reject-all {}.gravitoLightCMP-layer2-actions-settings {}.gravitoLightCMP-layer2-actions-accept-all {}",
        xs: 12,
        sm: 12,
      },
      {
        name: "logoUrl",
        type: "text",
        label: "Logo Url",
        placeholder: "Enter your logo url",
        tooltip: "Url of logo to be used in CMP.",
        guiderText:
          "Note: This field is recommended to be changed according to your needs.",
        required: false,
        default: "https://cdn.gravito.net/logos/gravito_logo.jpg",
        xs: 12,
        sm: 12,
      },
      {
        name: "logoSvg",
        type: "text",
        label: "Logo svg",
        placeholder: "Enter your logo svg",
        advanced: true,
        tooltip: "Svg of logo to be used in CMP.",
        required: false,
        default: "",
        xs: 12,
        sm: 12,
      },
      {
        name: "logoType",
        type: "select",
        label: "Logo type",
        placeholder: "Select your logo type",
        options: [
          { label: "Image", value: "img" },
          { label: "Svg", value: "svg" },
        ],
        advanced: true,
        tooltip: "Type of logo which you want to use.",
        required: false,
        default: "img",
        xs: 12,
        sm: 12,
      },
      {
        name: "primaryColor",
        type: "color",
        label: "Primary color code",
        placeholder: "Enter your primary color code",
        tooltip: "Primary color to be used in CMP.",
        guiderText:
          "Note: This field is recommended to be changed according to your needs.",
        required: false,
        default: "#387d9a",
        xs: 12,
        sm: 6,
      },
      {
        name: "secondaryColor",
        type: "color",
        label: "Secondary color code",
        placeholder: "Enter your secondary color code",
        tooltip: "Secondary color to be used in CMP.",
        guiderText:
          "Note: This field is recommended to be changed according to your needs.",
        required: false,
        default: "#757474",
        xs: 12,
        sm: 6,
      },
      {
        name: "disableConfirmationModal",
        type: "checkbox",
        label: "Do you want to disable confirmation modal",
        tooltip: "Check this if you don't want to show confirmation modal.",
        required: false,
        default: true,
        xs: 12,
        sm: 12,
      },
      {
        name: "fonts",
        type: "fonts",
        label: "Fonts",
        helperText: "Using this section you will be able to add fonts.",
        required: false,
        advanced: true,
        default: [
          {
            url: "https://cdn.gravito.net/fonts/lato-v22-latin-700.woff2",
            unicodeRange:
              "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;",
          },
          {
            url: "https://cdn.gravito.net/fonts/lato-v22-latin-700.woff2",
            unicodeRange:
              "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;",
          },
        ],
        xs: 12,
        sm: 12,
      },
    ],
    GtmTagModal: [
      {
        name: "triggerName",
        type: "text",
        label: "Trigger name",
        placeholder: "Enter tag trigger name",
        tooltip: "Name of the events that needs to added to dataLayer.",
        default: "",
        required: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "consentIds",
        type: "multiSelect",
        label: "Consent IDs",
        placeholder: "Select consent IDs",
        options: [],
        tooltip: "IDs of consents that gtm should watch.",
        default: [],
        xs: 12,
        sm: 12,
      },
      {
        name: "fireOnce",
        type: "checkbox",
        label: "Fire Once",
        tooltip:
          "Check this checkbox if you want to fire the gtm tag only once when the consent changes.",
        default: false,
        required: false,
        xs: 12,
        sm: 12,
      },
    ],
    ConsentModal: [
      {
        name: "id",
        type: "number",
        label: "Consent ID",
        placeholder: "Enter consent ID",
        tooltip: "This will be the ID of consent.",
        default: "",
        required: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "name",
        type: "text",
        label: "Consent Name",
        placeholder: "Enter consent name",
        tooltip: "This will be the Name of consent.",
        default: "",
        required: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "description",
        type: "text",
        label: "Consent Description",
        placeholder: "Enter consent description",
        multiline: true,
        minRows: 3,
        tooltip: "This will be the description of consent.",
        default: "",
        required: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "isConsentable",
        type: "checkbox",
        label: "Is Consentable",
        tooltip:
          "Check this checkbox if you want to show checkbox infront of this consent on second layer.",
        default: false,
        required: false,
        xs: 12,
        sm: 12,
      },
    ],
    FontModal: [
      {
        name: "url",
        type: "text",
        label: "Font URL",
        placeholder: "Enter font url",
        tooltip: "This will be the url of font.",
        default: "",
        required: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "unicodeRange",
        type: "text",
        label: "Font Unicode Range",
        placeholder: "Enter font unicode range",
        multiline: true,
        minRows: 3,
        tooltip: "This will be the unicode range of font.",
        default: "",
        required: false,
        xs: 12,
        sm: 12,
      },
    ],
  },

  languages: {
    english: {
      label: "English",
      languageCode: "en",
      iconUrl: "https://cdn.gravito.net/logos/English.png",
    },
    danish: {
      label: "Danish",
      languageCode: "da",
      iconUrl: "https://cdn.gravito.net/logos/Danish.png",
    },
    dutch: {
      label: "Dutch",
      languageCode: "nl",
      iconUrl: "https://cdn.gravito.net/logos/Dutch.png",
    },
    finnish: {
      label: "Finnish",
      languageCode: "fi",
      iconUrl: "https://cdn.gravito.net/logos/Finnish.png",
    },
    french: {
      label: "French",
      languageCode: "fr",
      iconUrl: "https://cdn.gravito.net/logos/French.png",
    },
    german: {
      label: "German",
      languageCode: "de",
      iconUrl: "https://cdn.gravito.net/logos/German.png",
    },
    norwegian: {
      label: "Norwegian",
      languageCode: "nb",
      iconUrl: "https://cdn.gravito.net/logos/Norwegian.png",
    },
    polish: {
      label: "Polish",
      languageCode: "pl",
      iconUrl: "https://cdn.gravito.net/logos/Polish.png",
    },
    swedish: {
      label: "Swedish",
      languageCode: "sv",
      iconUrl: "https://cdn.gravito.net/logos/Swedish.png",
    },
  },
};

export default schema;
