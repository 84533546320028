import { createSlice } from "@reduxjs/toolkit";
import {
  getAllConfigs,
  getConfigById,
  createConfig,
  updateConfig,
  publishConfig,
  deleteDraftConfig,
  deletePublishedConfig,
} from "../thunks/proCmpConfig";
import { NotificationManager } from "react-notifications";
import { configSchema } from "../pages/proCmpConfig/validation";
import schema from "../pages/proCmpConfig/mapper";
import _ from "lodash";

const initialState = {
  configName: "",
  config: {},
  schema: schema,
  errors: {},
  apiModel: {},
  availableLanguages: [
    {
      label: "English",
      languageCode: "en",
      iconUrl: "https://cdn.gravito.net/logos/English.png",
    },
    {
      label: "Danish",
      languageCode: "da",
      iconUrl: "https://cdn.gravito.net/logos/Danish.png",
    },
    {
      label: "Dutch",
      languageCode: "nl",
      iconUrl: "https://cdn.gravito.net/logos/Dutch.png",
    },
    {
      label: "Finnish",
      languageCode: "fi",
      iconUrl: "https://cdn.gravito.net/logos/Finnish.png",
    },
    {
      label: "French",
      languageCode: "fr",
      iconUrl: "https://cdn.gravito.net/logos/French.png",
    },
    {
      label: "German",
      languageCode: "de",
      iconUrl: "https://cdn.gravito.net/logos/German.png",
    },
    {
      label: "Norwegian",
      languageCode: "nb",
      iconUrl: "https://cdn.gravito.net/logos/Norwegian.png",
    },
    {
      label: "Polish",
      languageCode: "pl",
      iconUrl: "https://cdn.gravito.net/logos/Polish.png",
    },
    {
      label: "Swedish",
      languageCode: "sv",
      iconUrl: "https://cdn.gravito.net/logos/Swedish.png",
    },
  ],
};

const getDefaultConfig = ({
  mapper,
  sections,
  prevState = {},
  language = "en",
}) => {
  let obj = { ...prevState };
  const limitedMapper = sections ? _.pick(mapper, sections) : mapper;

  const setDefaultValue = (key, value) => {
    _.set(obj, key.replace("{lang}", language), value[language] || value);
  };

  _.values(limitedMapper).forEach((value) => {
    if (_.isArray(value)) {
      value.forEach((item) => {
        if (item.fields) {
          item.fields.forEach((field) => {
            setDefaultValue(field.name, field.default);
          });
        } else {
          setDefaultValue(item.name, item.default);
        }
      });
    } else if (_.isObject(value)) {
      if (value.name) {
        setDefaultValue(value.name, value.default);
      } else {
        obj = getDefaultConfig({
          mapper: value,
          prevState: obj,
          language,
        });
      }
    }
  });
  return obj;
};

// Function to validate config and return errors
const validateConfig = (config) => {
  const result = configSchema.safeParse(config);
  if (!result.success) {
    const errors = {};
    result.error.errors.forEach((error) => {
      _.set(errors, error.path.join("."), error.message);
    });
    return errors;
  }
  return {};
};

const proCmpConfigSlice = createSlice({
  name: "proCmpConfigSlice",
  reducers: {
    setConfigName: (state, action) => {
      state.configName = action.payload;
    },
    setConfig: (state, action) => {
      state.config =
        action.payload ||
        getDefaultConfig({
          mapper: state.schema,
          prevState: state.config,
          sections: ["settings", "styles"],
          language: "en",
        });
      state.errors = validateConfig(state.config);
    },
    setConfigSection: (state, action) => {
      const section = action.payload;
      _.set(
        state.config,
        section,
        getDefaultConfig({
          mapper: state.schema,
          sections: [section],
          prevState: state.config,
          language: "en",
        })[section]
      );
      state.errors = validateConfig(state.config);
    },
    removeConfigSection: (state, action) => {
      _.unset(state.config, action.payload);
      state.errors = validateConfig(state.config);
    },
    handleConfigChange: (state, action) => {
      const { name, value } = action.payload;
      _.set(state.config, name, value);
      state.errors = validateConfig(state.config);
    },
    addTranslation: (state, action) => {
      const { framework, languageCode, translations } = action.payload;
      _.set(
        state.config,
        `${framework}.languages.${languageCode}`,
        translations
      );
      state.errors = validateConfig(state.config);
    },
    removeTranslation: (state, action) => {
      const { framework, languageCode } = action.payload;
      _.unset(state.config, `${framework}.languages.${languageCode}`);
      state.errors = validateConfig(state.config);
    },
    syncConsents: (state, action) => {
      const framework = action.payload;
      const { consents } = state.config[framework].core;
      const { languages } = state.config[framework];
      _.forEach(languages, (language) => {
        // Merge global consents into language consents, keeping only valid items
        language.consents = _.unionBy(
          consents,
          _.filter(language.consents, (item) =>
            _.some(consents, (globalItem) => globalItem.id === item.id)
          ),
          "id"
        );
      });
      state.config[framework].languages = languages;
    },
    resetConfigurator: (state) => {
      state.configName = "";
      state.apiModel = {};
      state.config = {};
      state.errors = {};
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllConfigs.pending, (state, action) => {});
    builder.addCase(getAllConfigs.fulfilled, (state, action) => {});
    builder.addCase(getAllConfigs.rejected, (state, action) => {
      NotificationManager.error("Error fetching config list");
    });

    builder.addCase(getConfigById.pending, (state, action) => {});
    builder.addCase(getConfigById.fulfilled, (state, action) => {
      state.config = JSON.parse(action.payload.config);
      if (action.payload.status) {
        state.apiModel = action.payload;
        state.configName = action.payload.name;
      }
      state.errors = validateConfig(state.config);
    });
    builder.addCase(getConfigById.rejected, (state, action) => {
      NotificationManager.error("Error occured in fetching config");
    });

    builder.addCase(createConfig.pending, (state, action) => {});
    builder.addCase(createConfig.fulfilled, (state, action) => {
      NotificationManager.success("Config created successfully");
      state.apiModel = action.payload;
    });
    builder.addCase(createConfig.rejected, (state, action) => {
      NotificationManager.error("Error occured in creating draft config");
    });

    builder.addCase(updateConfig.pending, (state, action) => {});
    builder.addCase(updateConfig.fulfilled, (state, action) => {
      state.apiModel = action.payload;
      NotificationManager.success("Config updated successfully");
    });
    builder.addCase(updateConfig.rejected, (state, action) => {
      NotificationManager.error("Error occured in updating draft config");
    });

    builder.addCase(publishConfig.pending, (state, action) => {});
    builder.addCase(publishConfig.fulfilled, (state, action) => {
      state.apiModel = action.payload;
      NotificationManager.success("Config published successfully");
    });
    builder.addCase(publishConfig.rejected, (state, action) => {
      NotificationManager.error("Error occured in publishing config");
    });

    builder.addCase(deleteDraftConfig.pending, (state, action) => {});
    builder.addCase(deleteDraftConfig.fulfilled, (state, action) => {
      NotificationManager.success("Config deleted successfully");
    });
    builder.addCase(deleteDraftConfig.rejected, (state, action) => {
      NotificationManager.error("Error occured in deleting draft config");
    });

    builder.addCase(deletePublishedConfig.pending, (state, action) => {});
    builder.addCase(deletePublishedConfig.fulfilled, (state, action) => {
      NotificationManager.success("Config deleted successfully");
    });
    builder.addCase(deletePublishedConfig.rejected, (state, action) => {
      NotificationManager.error("Error occured in deleting published config");
    });
  },
});

export default proCmpConfigSlice.reducer;

export const {
  setConfigName,
  setConfig,
  setConfigSection,
  removeConfigSection,
  handleConfigChange,
  addTranslation,
  removeTranslation,
  syncConsents,
  resetConfigurator,
} = proCmpConfigSlice.actions;
