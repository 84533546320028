import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { WithNavBar, WithSideBar, WithBottomTab } from "../layouts/index,";
import Drawer from "@mui/material/Drawer";
import { Button, IconButton, Tooltip } from "@mui/material";

import { getFeatureHelp } from "../thunks/help";
import { useDispatch } from "react-redux";
import { useAuth } from "../contexts/auth";
import { CircularProgress } from "@mui/material";
import HelpComponent from "../components/helpContent";

// pages

const Reporting = React.lazy(() => import("../pages/reporting"));
const Dashboard = React.lazy(() => import("../pages/customerdashboard1"));
const CompareTrends = React.lazy(() => import("../pages/compareTrend"));
const LiteCmpConfig = React.lazy(() => import("../pages/liteCmpConfig"));
const TcfConfig = React.lazy(() => import("../pages/tcfConfig"));
const ProCmpConfig = React.lazy(() => import("../pages/proCmpConfig"));
const ManageDomains = React.lazy(() => import("../pages/manageDomains"));
const ManageDomainGroups = React.lazy(() =>
  import("../pages/manageDomainsGroups")
);
const FirstPartyDomains = React.lazy(() =>
  import("../pages/firstPartyDomains")
);
const ManageAudience = React.lazy(() => import("../pages/manageAudience"));
const ManageSegments = React.lazy(() => import("../pages/manageSegments"));
const Content = React.lazy(() => import("../pages/content"));
const Constellations = React.lazy(() => import("../pages/constellations"));
const ListOfCampaigns = React.lazy(() => import("../pages/listOfCampaigns"));
const ManageCampaigns = React.lazy(() => import("../pages/manageCampaigns"));
const IAMApps = React.lazy(() => import("../pages/iAMApps"));
const Crm = React.lazy(() => import("../pages/crm"));
const Connectors = React.lazy(() => import("../pages/connectors"));
const CookieScan = React.lazy(() => import("../pages/cookieScan"));
const CookieRepo = React.lazy(() => import("../pages/cookieRepo"));
const ManageCookieScan = React.lazy(() => import("../pages/manageCookieScan"));
const GvlUpdates = React.lazy(() => import("../pages/gvlUpdates"));
const FirstPartyConfigurator = React.lazy(() =>
  import("../pages/firstPartyConfigurator")
);
const CookieSettings = React.lazy(() => import("../pages/cookieSettings"));

const ManageAccount = React.lazy(() => import("../pages/manageAccount"));
const GettingStarted = React.lazy(() => import("../pages/gettingStarted"));
const Customers = React.lazy(() => import("../pages/customers"));
const Microprofile = React.lazy(() => import("../pages/microprofile"));

const MainApp = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { data } = useAuth();
  const [loading, setLoading] = useState(false);
  const [helpContent, setHelpContent] = useState(null);
  const [isDrawerOpen, setDrawer] = useState(false);
  const isNotFirstTimeVisit = localStorage.getItem("isNotFirstTimeVisit");
  const getHelpContent = async (currentPath) => {
    try {
      setLoading(true);
      let res = await dispatch(getFeatureHelp(currentPath)).unwrap();
      setHelpContent(res);
      setLoading(false);
    } catch (error) {
      console.log("error");
      setLoading(false);
    }
  };
  useEffect(() => {
    let currentPath;
    let isSelfServiceCustomer = data?.currentCustomer?.isSelfServiceCustomer;
    if (location.pathname.split("/").length > 2) {
      currentPath = location.pathname.split("/")[2];
    } else {
      currentPath = location.pathname.split("/")[1];
      if (currentPath == "app") {
        return;
      }
    }
    switch (currentPath) {
      case "tcfconfig":
        getHelpContent(currentPath + "_V2");

        break;
      case "manageaccount":
        if (isSelfServiceCustomer) {
          getHelpContent(currentPath + "_selfServiceCustomer");
        } else {
          getHelpContent(currentPath);
        }

        break;
      default:
        getHelpContent(currentPath);
    }
  }, [location.pathname]);

  if (!data) {
    return <Navigate to={"/landing"} />;
  }

  if (data && data.isNormalUser) {
    return <Navigate to={"/register"} />;
  }
  return (
    <>
      <WithNavBar>
        <div className="container-fluid main-app-container mx-0">
          <Routes>
            <Route
              path={"/"}
              element={
                <Navigate
                  replace
                  to={`${location.pathname}/${
                    isNotFirstTimeVisit == "true"
                      ? "customerdashboard"
                      : "gettingstarted"
                  }`}
                />
              }
            />
            <Route
              path={`/customerdashboard`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <Dashboard />
                </React.Suspense>
              }
            />
            <Route
              path={`/comparetrends`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <CompareTrends />
                </React.Suspense>
              }
            />
            <Route
              path={`/reports`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <Reporting />
                </React.Suspense>
              }
            />
            <Route
              path={`/gravitoCMPconfig/*`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <LiteCmpConfig />
                </React.Suspense>
              }
            />
            <Route
              path={`/tcfconfig/*`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <TcfConfig />
                </React.Suspense>
              }
            />
            {/* <Route
              path={`/proCMPconfig/*`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <ProCmpConfig />
                </React.Suspense>
              }
            /> */}
            <Route
              path={`/customerdomains/*`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <ManageDomains />
                </React.Suspense>
              }
            />
            <Route
              path={`/customerdomaingroups/*`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <ManageDomainGroups />
                </React.Suspense>
              }
            />
            <Route
              path={`/managecustomdomains/*`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <FirstPartyDomains />
                </React.Suspense>
              }
            />
            <Route
              path={`/audiences`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <ManageAudience />
                </React.Suspense>
              }
            />
            <Route
              path={`/segments`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <ManageSegments />
                </React.Suspense>
              }
            />
            <Route
              path={`/contents`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <Content />
                </React.Suspense>
              }
            />
            <Route
              path={`/constellations`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <Constellations />
                </React.Suspense>
              }
            />
            <Route
              path={`/campaigndashboard`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <ListOfCampaigns />
                </React.Suspense>
              }
            />
            <Route
              path={`/campaigns`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <ManageCampaigns />
                </React.Suspense>
              }
            />
            <Route
              path={`/applications/*`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <IAMApps />
                </React.Suspense>
              }
            />
            <Route
              path={`/domainprofiles`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <Crm />
                </React.Suspense>
              }
            />
            <Route
              path={`/connectors/*`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <Connectors />
                </React.Suspense>
              }
            />
            <Route
              path={`/cookiescan/*`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <CookieScan />
                </React.Suspense>
              }
            />
            <Route
              path={`/cookierepo/*`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <CookieRepo />
                </React.Suspense>
              }
            />
            <Route
              path={`/managecookiescan/*`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <ManageCookieScan />
                </React.Suspense>
              }
            />
            <Route
              path={`/gvlupdates`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <GvlUpdates />
                </React.Suspense>
              }
            />
            <Route
              path={`/firstpartyconfigurator`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <FirstPartyConfigurator />
                </React.Suspense>
              }
            />
            <Route
              path={`/cookieSettings`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <CookieSettings />
                </React.Suspense>
              }
            />
            <Route
              path={`/manageaccount`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <ManageAccount />
                </React.Suspense>
              }
            />
            <Route
              path={`/gettingstarted`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <GettingStarted />
                </React.Suspense>
              }
            />
            <Route
              path={`/customers/*`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <Customers />
                </React.Suspense>
              }
            />
            <Route
              path={`/microprofile/*`}
              element={
                <React.Suspense fallback={<CircularProgress />}>
                  <Microprofile />
                </React.Suspense>
              }
            />
          </Routes>

          <Button
            variant="contained"
            className="btn helpButton transform-90"
            style={{ position: "fixed", top: "50vh" }}
            onClick={() => {
              setDrawer((cv) => !cv);
            }}
          >
            Guide me{" "}
            {isDrawerOpen ? (
              <i className="ml-10 fa fa-arrow-up "></i>
            ) : (
              <i className="ml-10 fa fa-arrow-down"></i>
            )}
          </Button>
        </div>
      </WithNavBar>

      <Drawer
        anchor={"right"}
        open={isDrawerOpen}
        variant="temporary"
        onClose={() => {
          setDrawer(false);
        }}
      >
        <div
          style={{
            width: "40vw",
            minWidth: "200px",
            height: "100vh",
            display: "flex",
            justifyContent: "start",
          }}
        >
          <Tooltip
            id="tooltip-icon"
            placement="bottom"
            title={<div>Close</div>}
          >
            <IconButton
              id="closeHelp"
              className="text-primary iconbtn "
              aria-label="Close help"
              style={{ position: "fixed", right: 20, top: 0 }}
              onClick={() => setDrawer(false)}
            >
              <i className=" fa fa-close deletebutton"></i>
            </IconButton>
          </Tooltip>
          {helpContent && (
            <HelpComponent helpContent={helpContent}></HelpComponent>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default MainApp;
