import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosRequest, getContextObject } from "../helpers/index";

export const getCurrentCustomer = createAsyncThunk(
  "manageAccountThunk/getCurrentCustomer",
  async (model, thunkAPI) => {
    try {
      var currentCustomerContext = getContextObject()
        ? getContextObject().customerId
        : 0;

      const getData = async (url) => {
        try {
          const response = await getAxiosRequest().get(url);
          return response;
        } catch (error) {
          return { data: [] };
        }
      };

      const [customerData, costData, licensesData] = await Promise.all([
        getData(`/customer/current/${currentCustomerContext}`),
        getData(`/customer/${currentCustomerContext}/costing/all`),
        getData(`/customer/${currentCustomerContext}/domainlicense`),
      ]);

      // trim phone number of customer
      var currentCustomer = customerData.data;
      currentCustomer.phone = customerData.data.phone.trim();
      var response = {
        customer: currentCustomer,
        costs: costData.data,
        licenses: licensesData.data,
      };
      return response;
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const getCreditsList = createAsyncThunk(
  "manageAccountThunk/getCreditsList",
  async (model, thunkAPI) => {
    try {
      let response = await getAxiosRequest().get("Voucher/Types");
      if (response) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getUserProfile = createAsyncThunk(
  "manageAccountThunk/getUserProfile",
  async (model, thunkAPI) => {
    try {
      let response = await getAxiosRequest().get("/user");
      if (response) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const saveCustomerBasicInfo = createAsyncThunk(
  "manageAccountThunk/saveCustomerBasicInfo",
  async (model, thunkAPI) => {
    try {
      let response = await getAxiosRequest().put("/customer/basicinfo", model);
      if (response) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const registerNewDomains = createAsyncThunk(
  "manageAccountThunk/registerNewDomains",
  async (model, thunkAPI) => {
    try {
      let response = await getAxiosRequest().put("/customer", model);
      if (response) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteTeamMember = createAsyncThunk(
  "manageAccountThunk/deleteTeamMember",
  async (id, thunkAPI) => {
    try {
      let response = await getAxiosRequest().delete(
        `/customer/customeruser?id=${id}`
      );
      if (response) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const inviteTeamMember = createAsyncThunk(
  "manageAccountThunk/inviteTeamMember",
  async (model, thunkAPI) => {
    try {
      let response = await getAxiosRequest().post("/account/invite", model);
      if (response) {
        return {
          data: response.data,
          email: model.email,
        };
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error,
        email: model.email,
      });
    }
  }
);

export const getVoucherByCode = createAsyncThunk(
  "manageAccountThunk/getVoucherByCode",
  async (code, thunkAPI) => {
    try {
      let response = await getAxiosRequest().get(`/Voucher/${code}`);
      if (response) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const purchaseCredits = createAsyncThunk(
  "manageAccountThunk/purchaseCredits",
  async (model, thunkAPI) => {
    try {
      let response = await getAxiosRequest().post("/payment/stripe", model);
      if (response) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "manageAccountThunk/updateUserProfile",
  async (model, thunkAPI) => {
    try {
      let response = await getAxiosRequest().put("/user", model);
      if (response) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addDomainLicense = createAsyncThunk(
  "manageAccountThunk/addDomainLicense",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;
      model.customerId = currentCustomer;
      let response = await getAxiosRequest().post(
        `/customer/${currentCustomer}/domainlicense`,
        model
      );
      await thunkAPI.dispatch(getCurrentCustomer());
      if (response) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deactivateDomainLicense = createAsyncThunk(
  "manageAccountThunk/deactivateDomainLicense",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;
      model.customerId = 0;
      let response = await getAxiosRequest().put(
        `/customer/${currentCustomer}/domainlicense/${model.id}/end`,
        model
      );
      await thunkAPI.dispatch(getCurrentCustomer());
      if (response) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSubscriptions = createAsyncThunk(
  "manageAccountThunk/getSubscriptions",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;
      const res = await getAxiosRequest().get(
        `/customer/${currentCustomer}/subscription`
      );
      let subscriptionData = {};
      if (res.data) {
        subscriptionData = JSON.parse(res.data)[0];
      } else {
        subscriptionData = {
          StripeCustomerId: null,
          Domain: [],
        };
      }
      return subscriptionData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const buySubscriptions = createAsyncThunk(
  "manageAccountThunk/buySubscriptions",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;
      model.customerId = currentCustomer;
      let response = await getAxiosRequest().post(
        "/customer/subscription",
        model
      );

      if (response) {
        return response;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getDataAccessTokens = createAsyncThunk(
  "manageAccountThunk/getDataAccessTokens",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;
      const res = await getAxiosRequest().get(
        `/customer/${currentCustomer}/customerdataaccesstoken`
      );
      let dataAccessTokens = [];
      if (res.data) {
        dataAccessTokens = JSON.parse(res.data);
      }
      return dataAccessTokens;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createDataAccessToken = createAsyncThunk(
  "manageAccountThunk/createDataAccessToken",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;
      model.customerId = currentCustomer;
      let response = await getAxiosRequest().post(
        `/customer/${currentCustomer}/customerdataaccesstoken`
      );

      if (response) {
        return response;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// test method to test openApiController to access storage data for a domain
export const getCustomerData = createAsyncThunk(
  "manageAccountThunk/getCustomerData",
  async (model, thunkAPI) => {
    try {
      var config = {};
      config = {
        headers: {
          gravitoaccess:
            "sA2j2pD5KojHma/yt3AweSQ8gvmeXUZ2nhCOsxnpz8Ik04rg1f7+FX7JEdP+CbbG",
          gravitoversion: "v3",
        },
      };
      const res = await getAxiosRequest().get(
        `/gp/gravitocustomerdata?domainName=${model.domainName}`,
        config
      );
      let customerData = [];
      if (res.data) {
        customerData = JSON.parse(res.data);
      }
      return customerData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addDomainForCookieScan = createAsyncThunk(
  "manageAccountThunk/addDomainForCookieScan",
  async (model, thunkAPI) => {
    try {
      var currentCustomer = getContextObject()
        ? getContextObject().customerId
        : 0;
      model.customerId = currentCustomer;
      let response = await getAxiosRequest().post(
        `/customer/addDomainForCookieScan`,
        model
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getActiveCookieScanDomains = createAsyncThunk(
  "manageAccountThunk/getActiveCookieScanDomains",
  async (model, thunkAPI) => {
    try {
      var customerId = getContextObject() ? getContextObject().customerId : 0;
      let response = await getAxiosRequest().get(
        `/customer/${customerId}/getActiveCookieScanDomains`
      );
      return response.data ? JSON.parse(response.data) : [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteCookieScanDomain = createAsyncThunk(
  "manageAccountThunk/deleteCookieScanDomain",
  async (model, thunkAPI) => {
    try {
      var customerId = getContextObject() ? getContextObject().customerId : 0;
      let response = await getAxiosRequest().delete(
        `/customer/${customerId}/deleteCookieScanDomain?domainId=${model.domainId}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
