import { createSlice } from "@reduxjs/toolkit";
import {
  getCookieScanReport,
  updateCookieData,
  publishDomainCookieData,
  createCookieData,
  deleteCookieData,
} from "../thunks/manageCookieScan";
import { NotificationManager } from "react-notifications";
import _ from "lodash";

const initialState = {
  loading: false,
  domainCookieReport: [],
};

const manageCookieScanSlice = createSlice({
  name: "manageCookieScanSlice",
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    // fetch cookie scan report
    builder.addCase(getCookieScanReport.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCookieScanReport.fulfilled, (state, action) => {
      const cookieData = action.payload;
      const groupedData = _.groupBy(cookieData, "DomainName");
      const domainCookieData = Object.keys(groupedData).map((key) => ({
        domain: key,
        cookieData: groupedData[key],
      }));
      state.domainCookieReport = domainCookieData;
    });
    builder.addCase(getCookieScanReport.rejected, (state, action) => {
      NotificationManager.error("Error occured in fetching report");
      state.loading = false;
    });

    // update cookie data
    builder.addCase(updateCookieData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateCookieData.fulfilled, (state, action) => {
      NotificationManager.success("Cookie data updated successfully");
      state.loading = false;
    });
    builder.addCase(updateCookieData.rejected, (state, action) => {
      NotificationManager.error("Error occured in updating cookie data");
      state.loading = false;
    });

     // create cookie data
     builder.addCase(createCookieData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createCookieData.fulfilled, (state, action) => {
      NotificationManager.success("Cookie added successfully");
      state.loading = false;
    });
    builder.addCase(createCookieData.rejected, (state, action) => {
      NotificationManager.error("Error occured in adding cookie data");
      state.loading = false;
    });

    // delete cookie data
    builder.addCase(deleteCookieData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteCookieData.fulfilled, (state, action) => {
      NotificationManager.success("Cookie deleted successfully");
      state.loading = false;
    });
    builder.addCase(deleteCookieData.rejected, (state, action) => {
      NotificationManager.error("Error occured in deleting cookie");
      state.loading = false;
    });

    // publish domain cookie data
    builder.addCase(publishDomainCookieData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(publishDomainCookieData.fulfilled, (state, action) => {
      NotificationManager.success("Domain cookie data published successfully");
      state.loading = false;
    });
    builder.addCase(publishDomainCookieData.rejected, (state, action) => {
      NotificationManager.error(
        "Error occured in publishing domain cookie data"
      );
      state.loading = false;
    });
  },
});

export default manageCookieScanSlice.reducer;
