import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosRequest } from "../helpers/index";

export const getAllConfigs = createAsyncThunk(
  "proCmpConfig/getAllConfigs",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().get(
        `/cmpconfigurator/proconfig/${model.customerId}`
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getConfigById = createAsyncThunk(
  "proCmpConfig/getConfigById",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().get(`/cmpconfigurator/${model.id}`);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createConfig = createAsyncThunk(
  "proCmpConfig/createConfig",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().post(
        `/cmpconfigurator/proconfig/draft`,
        model
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateConfig = createAsyncThunk(
  "proCmpConfig/updateConfig",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().put(
        `/cmpconfigurator/proconfig/draft`,
        model
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const publishConfig = createAsyncThunk(
  "proCmpConfig/publishConfig",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().put(
        `/cmpconfigurator/proconfig`,
        model
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteDraftConfig = createAsyncThunk(
  "proCmpConfig/deleteDraftConfig",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().delete(
        `/cmpconfigurator/proconfig/${model.customerId}/draft/${model.id}`
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deletePublishedConfig = createAsyncThunk(
  "proCmpConfig/deletePublishedConfig",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().delete(
        `/cmpconfigurator/proconfig/${model.customerId}/${model.folderName}`
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
