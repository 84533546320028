const schema = {
  settings: [
    {
      name: "settings.useTopDomain",
      type: "checkbox",
      label: "Do you want to write cookie in top domain",
      tooltip: "It is the ID of element on click of which cmp will shown.",
      required: false,
      default: false,
      xs: 12,
      sm: 12,
    },
    {
      name: "settings.catchCMPEvents",
      type: "checkbox",
      label: "Enable CMP Analytics",
      tooltip:
        "Check this box if you want to get real-time data of CMP stats on the Gravito admin dashboard.",
      required: false,
      default: true,
      xs: 12,
      sm: 12,
    },
    {
      name: "settings.useGravitoBackend",
      type: "hidden",
      label: "Use Gravito Backend",
      tooltip:
        "Check this box if you want to enable Gravito backend capabilities with your CMP",
      required: false,
      default: false,
      xs: 12,
      sm: 12,
    },
    {
      name: "settings.syncAfter",
      type: "number",
      label: "Sync After(in seconds)",
      placeholder: "Enter sync after",
      tooltip:
        "This is the delay in seconds till which backend sync will not occur even after occurence of sync events.",
      required: false,
      default: 1800,
      xs: 12,
      sm: 12,
    },
    {
      name: "settings.disableConfirmationModal",
      type: "checkbox",
      label: "Do you want to disable confirmation modal",
      tooltip: "Check this if you don't want to show confirmation modal.",
      required: false,
      default: true,
      xs: 12,
      sm: 12,
    },
    {
      name: "settings.sdkVersion",
      type: "hidden",
      default: 4,
    },
    {
      name: "settings.version",
      type: "hidden",
      default: "bundle_latest_5",
    },
  ],
  styles: [
    {
      name: "styles.logoUrl",
      type: "text",
      label: "Logo Url",
      placeholder: "Enter your logo url",
      tooltip: "Url of logo to be used in CMP.",
      required: false,
      default:
        "https://gravitocdn.blob.core.windows.net/logos/Website_DEMO_logo.png",
      xs: 12,
      sm: 12,
    },
    {
      name: "styles.primaryColor",
      type: "color",
      label: "Primary color code",
      placeholder: "Enter your primary color code",
      tooltip: "Primary color to be used in CMP.",
      required: false,
      default: "#f07e26",
      xs: 12,
      sm: 6,
    },
    {
      name: "styles.secondaryColor",
      type: "color",
      label: "Secondary color code",
      placeholder: "Enter your secondary color code",
      tooltip: "Secondary color to be used in CMP.",
      required: false,
      default: "#a3a3a3",
      xs: 12,
      sm: 6,
    },
    {
      name: "styles.headerColor",
      type: "color",
      label: "Header color code",
      placeholder: "Enter your header color code",
      tooltip: "Header color to be used in CMP.",
      required: false,
      default: "#f07e26",
      xs: 12,
      sm: 6,
    },
    {
      name: "styles.footerColor",
      type: "color",
      label: "Footer color code",
      placeholder: "Enter your footer color code",
      tooltip: "Footer color to be used in CMP.",
      required: false,
      default: "#f07e26",
      xs: 12,
      sm: 6,
    },
    {
      name: "styles.fonts",
      type: "fonts",
      label: "Fonts",
      helperText: "Using this section you will be able to add fonts.",
      required: false,
      default: [
        {
          url: "https://cdn.gravito.net/fonts/lato-v22-latin-700.woff2",
          unicodeRange:
            "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;",
        },
        {
          url: "https://cdn.gravito.net/fonts/lato-v22-latin-700.woff2",
          unicodeRange:
            "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;",
        },
      ],
      modalSchema: [
        {
          name: "url",
          type: "text",
          label: "Font URL",
          placeholder: "Enter font url",
          tooltip: "This will be the url of font.",
          default: "",
          required: false,
          xs: 12,
          sm: 12,
        },
        {
          name: "unicodeRange",
          type: "text",
          label: "Font Unicode Range",
          placeholder: "Enter font unicode range",
          multiline: true,
          minRows: 3,
          tooltip: "This will be the unicode range of font.",
          default: "",
          required: false,
          xs: 12,
          sm: 12,
        },
      ],
      xs: 12,
      sm: 12,
    },
  ],
  gravitoCMP: {
    core: [
      {
        name: "gravitoCMP.core.consents",
        type: "consents",
        label: "Consents",
        helperText:
          "Using this section you will be able to configure the consents.",
        required: false,
        default: {
          da: [
            {
              type: "consent",
              id: 1,
              name: "Essentielle cookies",
              description:
                "Essentielle cookies og lignende teknologier implementerer de basale funktioner på hjemmesiden, så som sidenavigation, brug af formularer og funktionalitet i indkøbskurven. Uden disse teknologier fungerer hjemmesiden ikke korrekt.",
              isConsentable: false,
              cookieCategories: ["Security"],
            },
            {
              type: "consent",
              id: 2,
              name: "Funktionelle cookies",
              description:
                "Funktionelle cookies og lignende teknologier gør det muligt at gemme oplysninger, der ændrer måden hjemmesiden vises for dig eller fungerer på, f.eks. dit foretrukne sprog.",
              isConsentable: true,
              cookieCategories: ["Functional"],
            },
            {
              type: "consent",
              id: 3,
              name: "Statistiske cookies",
              description:
                "Statistiske cookies og lignende teknologier giver os mulighed for at indsamle oplysninger om, hvordan vores hjemmeside anvendes. Disse oplysninger hjælper os med at forbedre indholdet og brugbarheden af hjemmesiden.",
              isConsentable: true,
              cookieCategories: ["Analytics"],
            },
            {
              type: "consent",
              id: 4,
              name: "Markedsførings- og reklame-cookies",
              description:
                "Markedsførings-cookies og lignende teknologier bruges til at spore besøgende på tværs af hjemmesiden. Hensigten er at vise annoncer, der er relevante og interessante for dig, og derfor mere værdifulde for os og tredjepartsannoncører.",
              isConsentable: true,
              cookieCategories: ["Marketing"],
            },
            {
              type: "consent",
              id: 5,
              name: "Ikke-kategoriserede cookies",
              description:
                "Disse cookies er i øjeblikket ikke tildelt en bestemt kategori og kan tjene flere funktioner på hjemmesiden. De bruges typisk til at understøtte webstedsfunktionalitet, indsamle indsigter til forbedringer eller overvåge brugerengagement. Når vi yderligere analyserer disse cookies, vil de blive kategoriseret i overensstemmelse hermed for at give mere gennemsigtighed i deres rolle og formål.",
              isConsentable: true,
              cookieCategories: ["Uncategorised"],
            },
          ],
          en: [
            {
              type: "consent",
              id: 1,
              name: "Essential cookies",
              description:
                "Essential cookies and similar technologies are implementing the basic functions of the website such as page navigation, use of forms and shopping cart functionality. Without these technologies the website will not work properly.",
              isConsentable: false,
              cookieCategories: ["Security"],
            },
            {
              type: "consent",
              id: 2,
              name: "Functional cookies",
              description:
                "Functional cookies and similar technologies make it possible to save information that changes the way the website appears to you or functions, e.g. your preferred language.",
              isConsentable: true,
              cookieCategories: ["Functional"],
            },
            {
              type: "consent",
              id: 3,
              name: "Statistical cookies",
              description:
                "Statistical cookies and similar technologies allow us to collect information about how our website is used. This information helps us to improve the content and usability of the website.",
              isConsentable: true,
              cookieCategories: ["Analytics"],
            },
            {
              type: "consent",
              id: 4,
              name: "Marketing & advertising cookies",
              description:
                "Marketing cookies and similar technologies are used to track visitors across websites. The intention is to display ads that are relevant and interesting to you and thus more valuable for us and third-party advertisers.",
              isConsentable: true,
              cookieCategories: ["Marketing"],
            },
            {
              type: "consent",
              id: 5,
              name: "Uncategorised Cookies",
              description:
                "These cookies are currently not assigned to a specific category and may serve multiple functions within the website. They are typically being used to support website functionality, gather insights for improvements, or monitor user engagement. As we further analyze these cookies, they will be categorized accordingly to provide more transparency into their role and purpose.",
              isConsentable: true,
              cookieCategories: ["Uncategorised"],
            },
          ],
          fr: [
            {
              type: "consent",
              id: 1,
              name: "Cookies nécessaires",
              description:
                "Les cookies nécessaires et technologies similaires permettent d'exécuter les fonctionnalités de base du site internet telles que la navigation sur les pages, l'utilisation des formulaires et le panier d'achat. Sans ces technologies, le site internet ne fonctionnera pas correctement.",
              isConsentable: false,
              cookieCategories: ["Security"],
            },
            {
              type: "consent",
              id: 2,
              name: "Cookies fonctionnels",
              description:
                "Les cookies fonctionnels et technologies similaires permettent d'enregistrer les informations qui modifient l'apparence du site internet ou la manière dont il fonctionne (par exemple, la langue que vous avez choisie).",
              isConsentable: true,
              cookieCategories: ["Functional"],
            },
            {
              type: "consent",
              id: 3,
              name: "Cookies de statistiques",
              description:
                "Les cookies de statistiques et technologies similaires nous permettent de recueillir des informations sur la façon dont notre site internet est utilisé. Celles-ci nous aident à améliorer le contenu et la convivialité du site internet.",
              isConsentable: true,
              cookieCategories: ["Analytics"],
            },
            {
              type: "consent",
              id: 4,
              name: "Cookies publicitaires et de marketing",
              description:
                "Marketing cookies and similar technologies are used to track visitors across websites. The intention is to display ads that are relevant and interesting to you and thus more valuable for us and third-party advertisers.",
              isConsentable: true,
              cookieCategories: ["Marketing"],
            },
            {
              type: "consent",
              id: 5,
              name: "Cookies non catégorisés",
              description:
                "Ces cookies ne sont actuellement pas attribués à une catégorie spécifique et peuvent remplir plusieurs fonctions au sein du site internet. Ils sont généralement utilisés pour soutenir la fonctionnalité du site internet, recueillir des informations pour des améliorations ou surveiller l'engagement des utilisateurs. Au fur et à mesure de l'analyse de ces cookies, ils seront classés en conséquence pour fournir plus de transparence sur leur rôle et leur objectif.",
              isConsentable: true,
              cookieCategories: ["Uncategorised"],
            },
          ],
          nl: [
            {
              type: "consent",
              id: 1,
              name: "Noodzakelijke cookies",
              description:
                "Noodzakelijke cookies en soortgelijke technologieën implementeren de basisfuncties van de website, zoals de navigatie op pagina's, het gebruik van formulieren en de functionaliteit van de winkelwagen. Zonder deze technologieën zal de website niet goed werken.",
              isConsentable: false,
              cookieCategories: ["Security"],
            },
            {
              type: "consent",
              id: 2,
              name: "Functionele cookies",
              description:
                "Functionele cookies en vergelijkbare technologieën maken het mogelijk om informatie op te slaan die de manier verandert waarop de website voor jou verschijnt of functioneert, zoals je voorkeurstaal.",
              isConsentable: true,
              cookieCategories: ["Functional"],
            },
            {
              type: "consent",
              id: 3,
              name: "Analytische cookies",
              description:
                "Analytische cookies en vergelijkbare technologieën zorgen ervoor dat we informatie kunnen verzamelen over hoe onze website gebruikt wordt. Deze informatie helpt ons de inhoud en bruikbaarheid van de website te verbeteren.",
              isConsentable: true,
              cookieCategories: ["Analytics"],
            },
            {
              type: "consent",
              id: 4,
              name: "Marketing- & trackingcookies",
              description:
                "Marketingcookies en vergelijkbare technologieën worden gebruikt om bezoekers op verschillende websites te volgen. De bedoeling is om advertenties weer te geven die voor jou relevant en interessant zijn en dus waardevoller zijn voor ons en externe adverteerders.",
              isConsentable: true,
              cookieCategories: ["Marketing"],
            },
            {
              type: "consent",
              id: 5,
              name: "Niet-gecategoriseerde cookies",
              description:
                "Deze cookies zijn momenteel niet toegewezen aan een specifieke categorie en kunnen meerdere functies binnen de website vervullen. Ze worden meestal gebruikt om de functionaliteit van de website te ondersteunen, inzichten te verzamelen voor verbeteringen of gebruikersbetrokkenheid te monitoren. Naarmate we deze cookies verder analyseren, worden ze dienovereenkomstig gecategoriseerd om meer transparantie te bieden over hun rol en doel.",
              isConsentable: true,
              cookieCategories: ["Uncategorised"],
            },
          ],
          fi: [
            {
              type: "consent",
              id: 1,
              name: "Välttämättömät evästeet",
              description:
                "Palvelu käyttää välttämättömiä evästeitä esim. kirjautumistietojen tallentamiseen.",
              isConsentable: false,
              cookieCategories: ["Security"],
            },
            {
              type: "consent",
              id: 2,
              name: "Toiminnalliset evästeet",
              description:
                "Palvelu käyttää toiminnallisia evästeitä esim. asetusten tallentamiseen sovelluksessa, nämä evästeet eivät sisällä henkilötietoja tai -tunnisteita.",
              isConsentable: true,
              cookieCategories: ["Functional"],
            },
            {
              type: "consent",
              id: 3,
              name: "Tilastolliset evästeet",
              description:
                "Palvelu käyttää tilastollisia evästeitä seuratakseen miten sivustoa tai sovellusta käytetään, kerätty tieto on anonymisoitua.",
              isConsentable: true,
              cookieCategories: ["Analytics"],
            },
            {
              type: "consent",
              id: 4,
              name: "Markkinoinnin evästeet",
              description:
                "Palvelu käyttää markkinointiin liittyviä evästeitä, ne voivat seurata käyttäjää eri sivustoilla ja sovelluksissa, mahdollistaen sisällön ja mainonnan paremman kohdentamisen ja käyttökokemuksen optimoinnin.",
              isConsentable: true,
              cookieCategories: ["Marketing"],
            },
            {
              type: "consent",
              id: 5,
              name: "Luokittelemattomat evästeet",
              description:
                "Nämä evästeet eivät ole tällä hetkellä määritetty mihinkään tiettyyn luokkaan ja ne voivat palvella useita toimintoja verkkosivustolla. Niitä käytetään yleensä verkkosivuston toiminnallisuuden tukemiseen, parannusten tietojen keräämiseen tai käyttäjien osallistumisen seurantaan. Kun analysoimme näitä evästeitä edelleen, ne luokitellaan vastaavasti tarjotaksemme lisää läpinäkyvyyttä niiden rooliin ja tarkoitukseen.",
              isConsentable: true,
              cookieCategories: ["Uncategorised"],
            },
          ],
          nb: [
            {
              type: "consent",
              id: 1,
              name: "Viktige informasjonskapsler",
              description:
                "Viktige informasjonskapsler og lignende teknologi implementerer de grunnleggende funksjonene til nettstedet, som f.eks. navigasjon på sidene, bruk av skjemaer og handlevogn-funksjonalitet. Uten disse teknologiene vil nettstedet ikke fungere slik det skal.",
              isConsentable: false,
              cookieCategories: ["Security"],
            },
            {
              type: "consent",
              id: 2,
              name: "Funksjonelle informasjonskapsler",
              description:
                "Funksjonelle informasjonskapsler og lignende teknologi gjør det mulig å lagre informasjon som endrer hvordan nettstedet ser ut for deg eller fungerer, f.eks. hvilket språk du foretrekker.",
              isConsentable: true,
              cookieCategories: ["Functional"],
            },
            {
              type: "consent",
              id: 3,
              name: "Statistiske informasjonskapsler",
              description:
                "Statistiske informasjonskapsler og lignende teknologi gjør at vi kan samle informasjon om hvordan nettstedet vårt brukes. Denne informasjonen hjelper oss med å forbedre innholdet på nettstedet og gjøre det nyttigere og mer brukervennlig.",
              isConsentable: true,
              cookieCategories: ["Analytics"],
            },
            {
              type: "consent",
              id: 4,
              name: "Informasjonskapsler for markedsføring og annonser",
              description:
                "Informasjonskapsler for markedsføring og lignende teknologi brukes til å spore besøkende på  tvers av nettsteder. Med dette ønsker vi å vise annonser som passer og er interessante for deg, og som slik sett er mer verdifulle for oss og tredjeparts annonsører.",
              isConsentable: true,
              cookieCategories: ["Marketing"],
            },
            {
              type: "consent",
              id: 5,
              name: "Ikke-kategoriserte informasjonskapsler",
              description:
                "Disse informasjonskapslene er for øyeblikket ikke tildelt en bestemt kategori og kan tjene flere funksjoner på nettstedet. De brukes vanligvis til å støtte nettstedsfunksjonalitet, samle innsikt for forbedringer eller overvåke brukerengasjement. Når vi analyserer disse informasjonskapslene videre, vil de bli kategorisert i samsvar med dette for å gi mer åpenhet om deres rolle og formål.",
              isConsentable: true,
              cookieCategories: ["Uncategorised"],
            },
          ],
          sv: [
            {
              type: "consent",
              id: 1,
              name: "Nödvändiga",
              description:
                "Nödvändiga cookies och liknande tekniker behövs för de grundläggande funktionerna inom webbplatsen, exempelvis navigering mellan sidor, användning av formulär samt kundvagn. Utan dessa cookies kommer webbplatsen inte att fungera korrekt.",
              isConsentable: false,
              cookieCategories: ["Security"],
            },
            {
              type: "consent",
              id: 2,
              name: "Functionella cookies",
              description:
                "Funktionella cookies och liknande tekniker gör det möjligt att spara information som förändrar webbplatsens utseende och funktionalitet för dig. Det är exempelvis dina inställningar för språk.",
              isConsentable: true,
              cookieCategories: ["Functional"],
            },
            {
              type: "consent",
              id: 3,
              name: "Analys och användarupplevelse",
              description:
                "Cookies och liknande tekniker relaterade till analys och användarupplevelse ger oss möjlighet att samla in information om hur vår webbplats används. Denna information hjälper oss förbättra webbplatsens innehåll och funktionalitet.",
              isConsentable: true,
              cookieCategories: ["Analytics"],
            },
            {
              type: "consent",
              id: 4,
              name: "Marknadsföring",
              description:
                "Marknadsföringscookies och liknande tekniker används för att spåra besökare mellan olika webbplatser. Avsikten är att visa annonser som är relevanta och intressanta för dig, och därmed mer värdefulla för oss och för tredjepartsannonsörer.",
              isConsentable: true,
              cookieCategories: ["Marketing"],
            },
            {
              type: "consent",
              id: 5,
              name: "Okategoriserade cookies",
              description:
                "Dessa cookies är för närvarande inte tilldelade en specifik kategori och kan tjäna flera funktioner inom webbplatsen. De används vanligtvis för att stödja webbplatsens funktionalitet, samla insikter för förbättringar eller övervaka användarengagemang. När vi analyserar dessa cookies ytterligare kommer de att kategoriseras i enlighet med detta för att ge mer transparens i deras roll och syfte.",
              isConsentable: true,
              cookieCategories: ["Uncategorised"],
            },
          ],
          de: [
            {
              type: "consent",
              id: 1,
              name: "Notwendige Cookies",
              description:
                "Notwendige Cookies und \u00e4hnliche Technologien implementieren die Grundfunktionen der Website wie die Seitennavigation, die Verwendung von Formularen und die Warenkorbfunktion. Ohne diese Technologien kann die Website nicht richtig funktionieren.",
              isConsentable: false,
              cookieCategories: ["Security"],
            },
            {
              type: "consent",
              id: 2,
              name: "Funktionale Cookies",
              description:
                "Funktionale Cookies und \u00e4hnliche Technologien erm\u00f6glichen die Speicherung von Informationen, die das Erscheinungsbild oder die Funktionsweise der Website ver\u00e4ndern, z.\u00a0B. Ihre bevorzugte Sprache.",
              isConsentable: true,
              cookieCategories: ["Functional"],
            },
            {
              type: "consent",
              id: 3,
              name: "Statistische Cookies",
              description:
                "Statistische Cookies und \u00e4hnliche Technologien erm\u00f6glichen es uns, Informationen dar\u00fcber zu sammeln, wie unsere Website genutzt wird. Diese Informationen helfen uns, den Inhalt und die Benutzerfreundlichkeit der Website zu verbessern.",
              isConsentable: true,
              cookieCategories: ["Analytics"],
            },
            {
              type: "consent",
              id: 4,
              name: "Marketing- und Werbe-Cookies",
              description:
                "Marketing-Cookies und \u00e4hnliche Technologien werden verwendet, um Besucher auf verschiedenen Websites zu verfolgen. Die Absicht dahinter ist, Anzeigen zu schalten, die f\u00fcr Sie relevant und interessant sind und somit f\u00fcr uns und Drittanbieter wertvoller sind.",
              isConsentable: true,
              cookieCategories: ["Marketing"],
            },
            {
              type: "consent",
              id: 5,
              name: "Nicht kategorisierte Cookies",
              description:
                "Diese Cookies sind derzeit keiner spezifischen Kategorie zugeordnet und können mehrere Funktionen innerhalb der Website erfüllen. Sie werden in der Regel verwendet, um die Funktionalität der Website zu unterstützen, Erkenntnisse für Verbesserungen zu sammeln oder das Nutzerengagement zu überwachen. Wenn wir diese Cookies weiter analysieren, werden sie entsprechend kategorisiert, um mehr Transparenz über ihre Rolle und ihren Zweck zu bieten.",
              isConsentable: true,
              cookieCategories: ["Uncategorised"],
            },
          ],
          pl: [
            {
              type: "consent",
              id: 1,
              name: "Niezb\u0119dne pliki cookie",
              description:
                "Niezb\u0119dne pliki cookie i podobne technologie umo\u017cliwiaj\u0105 realizacj\u0119 podstawowych funkcji strony, takich jak nawigacja, korzystanie z formularzy i koszyka. Bez tych technologii strona nie b\u0119dzie dzia\u0142a\u0107 poprawnie.",
              isConsentable: false,
              cookieCategories: ["Security"],
            },
            {
              type: "consent",
              id: 2,
              name: "Funkcjonalne pliki cookie",
              description:
                "Funkcjonalne pliki cookie i podobne technologie pozwalaj\u0105 na zapisywanie informacji, kt\u00f3re zmieniaj\u0105 spos\u00f3b prezentacji i dzia\u0142ania strony, np. preferowany j\u0119zyk.",
              isConsentable: true,
              cookieCategories: ["Functional"],
            },
            {
              type: "consent",
              id: 3,
              name: "Statystyczne pliki cookie",
              description:
                "Statystyczne pliki cookie i podobne technologii pozwalaj\u0105 nam zbiera\u0107 informacje o tym, jak nasza strona jest u\u017cywana. Te informacje pomagaj\u0105 nam udoskonala\u0107 jej zawarto\u015b\u0107 i u\u017cyteczno\u015b\u0107.",
              isConsentable: true,
              cookieCategories: ["Analytics"],
            },
            {
              type: "consent",
              id: 4,
              name: "Marketingowe i reklamowe pliki cookie",
              description:
                "Marketingowe pliki cookie i podobne technologie s\u0142u\u017c\u0105 do monitorowania aktywno\u015bci u\u017cytkownik\u00f3w na stronach. Ich celem jest umo\u017cliwienie wy\u015bwietlania trafnych i interesuj\u0105cych reklam, co jest korzystne dla nas i zewn\u0119trznych reklamodawc\u00f3w.",
              isConsentable: true,
              cookieCategories: ["Marketing"],
            },
            {
              type: "consent",
              id: 5,
              name: "Nieklasyfikowane pliki cookie",
              description:
                "Te pliki cookie nie są obecnie przypisane do określonej kategorii i mogą pełnić wiele funkcji na stronie internetowej. Zazwyczaj służą one do wspierania funkcjonalności strony internetowej, zbierania informacji w celu wprowadzenia usprawnień lub monitorowania zaangażowania użytkownika. W miarę dalszej analizy tych plików cookie zostaną one odpowiednio sklasyfikowane, aby zapewnić większą przejrzystość ich roli i celu.",
              isConsentable: true,
              cookieCategories: ["Uncategorised"],
            },
          ],
        },
        modalSchema: [
          {
            name: "id",
            type: "number",
            label: "Consent ID",
            placeholder: "Enter consent ID",
            tooltip: "This will be the ID of consent.",
            default: "",
            required: false,
            xs: 12,
            sm: 12,
          },
          {
            name: "name",
            type: "text",
            label: "Consent Name",
            placeholder: "Enter consent name",
            tooltip: "This will be the Name of consent.",
            default: "",
            required: false,
            xs: 12,
            sm: 12,
          },
          {
            name: "description",
            type: "text",
            label: "Consent Description",
            placeholder: "Enter consent description",
            multiline: true,
            minRows: 3,
            tooltip: "This will be the description of consent.",
            default: "",
            required: false,
            xs: 12,
            sm: 12,
          },
          {
            name: "cookieCategories",
            type: "multiSelect",
            label: "Cookie Categories",
            placeholder: "Select cookie categories",
            getOptions: () => [
              { value: "Security", label: "Security" },
              { value: "Functional", label: "Functional" },
              { value: "Analytics", label: "Analytics" },
              { value: "Marketing", label: "Marketing" },
              { value: "Uncategorised", label: "Uncategorised" },
            ],
            optionValue: "value",
            getOptionLabel: (option) => option.label,
            tooltip: "",
            default: [],
            xs: 12,
            sm: 12,
          },
          {
            name: "isConsentable",
            type: "checkbox",
            label: "Is Consentable",
            tooltip:
              "Check this checkbox if you want to show checkbox infront of this consent on second layer.",
            default: false,
            required: false,
            xs: 12,
            sm: 12,
          },
        ],
        xs: 12,
        sm: 12,
      },
      {
        name: "gravitoCMP.core.useGCM",
        type: "checkbox",
        label: "GCM-V2 Setup",
        default: true,
        helperText:
          "Using this section you can configure the Google Consent Mode V2. Please select mapping for google required consents.",
        xs: 12,
        sm: 12,
      },
      {
        name: "gravitoCMP.core.adsConsentId",
        type: "select",
        label: "Ads consent ID",
        placeholder: "Enter ads consent ID",
        tooltip: "It is the ID of element on click of which cmp will shown.",
        dependencies: [
          "config.gravitoCMP.core.useGCM",
          "config.gravitoCMP.core.consents",
        ],
        getOptions: (dependencyData) =>
          dependencyData?.consents?.map(({ id, name }) => ({
            label: name,
            value: id,
          })) || [],
        visible: (dependencyData) => dependencyData.useGCM,
        default: "",
        xs: 12,
        sm: 6,
      },
      {
        name: "gravitoCMP.core.adsUserDataConsentId",
        type: "select",
        label: "User data consent ID",
        placeholder: "Enter User Data consent ID",
        tooltip:
          "It is the ID of User Data consent that will be shown on second layer of Gravito CMP.",
        dependencies: [
          "config.gravitoCMP.core.useGCM",
          "config.gravitoCMP.core.consents",
        ],
        getOptions: (dependencyData) =>
          dependencyData?.consents?.map(({ id, name }) => ({
            label: name,
            value: id,
          })) || [],
        visible: (dependencyData) => dependencyData.useGCM,
        default: "",
        xs: 12,
        sm: 6,
      },
      {
        name: "gravitoCMP.core.adsPersonalizationConsentId",
        type: "select",
        label: "Personalization consent ID",
        placeholder: "Enter Personalization consent ID",
        tooltip:
          "It is the ID of Personalization consent that will be shown on second layer of Gravito CMP.",
        dependencies: [
          "config.gravitoCMP.core.useGCM",
          "config.gravitoCMP.core.consents",
        ],
        getOptions: (dependencyData) =>
          dependencyData?.consents?.map(({ id, name }) => ({
            label: name,
            value: id,
          })) || [],
        visible: (dependencyData) => dependencyData.useGCM,
        default: "",
        xs: 12,
        sm: 6,
      },
      {
        name: "gravitoCMP.core.analyticsConsentId",
        type: "select",
        label: "Analytics consent ID",
        placeholder: "Enter analytics consent ID",
        tooltip:
          "It is the ID of Analytics consent that will be shown on second layer of Gravito CMP.",
        dependencies: [
          "config.gravitoCMP.core.useGCM",
          "config.gravitoCMP.core.consents",
        ],
        getOptions: (dependencyData) =>
          dependencyData?.consents?.map(({ id, name }) => ({
            label: name,
            value: id,
          })) || [],
        visible: (dependencyData) => dependencyData.useGCM,
        default: "",
        xs: 12,
        sm: 6,
      },
      {
        name: "gravitoCMP.core.functionalityStorageId",
        type: "select",
        label: "Functionality Storage consent ID",
        placeholder: "Enter functionality storage consent ID",
        tooltip:
          "It is the ID of Functionality Storage consent that will be shown on second layer of Gravito CMP.",
        dependencies: [
          "config.gravitoCMP.core.useGCM",
          "config.gravitoCMP.core.consents",
        ],
        getOptions: (dependencyData) =>
          dependencyData?.consents?.map(({ id, name }) => ({
            label: name,
            value: id,
          })) || [],
        visible: (dependencyData) => dependencyData.useGCM,
        default: "",
        xs: 12,
        sm: 6,
      },
      {
        name: "gravitoCMP.core.personalizationStorageId",
        type: "select",
        label: "Personalization Storage consent ID",
        placeholder: "Enter Personalization storage consent ID",
        tooltip:
          "It is the ID of Personalization Storage consent that will be shown on second layer of Gravito CMP.",
        dependencies: [
          "config.gravitoCMP.core.useGCM",
          "config.gravitoCMP.core.consents",
        ],
        getOptions: (dependencyData) =>
          dependencyData?.consents?.map(({ id, name }) => ({
            label: name,
            value: id,
          })) || [],
        visible: (dependencyData) => dependencyData.useGCM,
        default: "",
        xs: 12,
        sm: 6,
      },
      {
        name: "gravitoCMP.core.securityStorageId",
        type: "select",
        label: "Security Storage consent ID",
        placeholder: "Enter Security storage consent ID",
        tooltip:
          "It is the ID of Security Storage consent that will be shown on second layer of Gravito CMP.",
        dependencies: [
          "config.gravitoCMP.core.useGCM",
          "config.gravitoCMP.core.consents",
        ],
        getOptions: (dependencyData) =>
          dependencyData?.consents?.map(({ id, name }) => ({
            label: name,
            value: id,
          })) || [],
        visible: (dependencyData) => dependencyData.useGCM,
        default: "",
        xs: 12,
        sm: 6,
      },
      {
        name: "gravitoCMP.core.gtmTags",
        type: "gtmTags",
        label: "GTM Tags",
        helperText:
          "Only for custom setup. You don't have to worry about these settings.",
        default: [],
        modalSchema: [
          {
            name: "triggerName",
            type: "text",
            label: "Trigger name",
            placeholder: "Enter tag trigger name",
            tooltip: "Name of the events that needs to added to dataLayer.",
            default: "",
            required: false,
            xs: 12,
            sm: 12,
          },
          {
            name: "consentIds",
            type: "multiSelect",
            label: "Consents",
            placeholder: "Select consents",
            tooltip: "IDs of consents that gtm should watch.",
            dependencies: ["config.gravitoCMP.core.consents"],
            getOptions: (dependencyData) =>
              dependencyData?.consents?.map(({ id, name }) => ({
                label: name,
                value: id,
              })) || [],
            default: [],
            xs: 12,
            sm: 12,
          },
          {
            name: "fireOnce",
            type: "checkbox",
            label: "Fire Once",
            tooltip:
              "Check this checkbox if you want to fire the gtm tag only once when the consent changes.",
            default: false,
            required: false,
            xs: 12,
            sm: 12,
          },
        ],
        xs: 12,
        sm: 12,
      },
      {
        name: "gravitoCMP.core.cookieName",
        type: "text",
        label: "Cookie name",
        placeholder: "Enter your cookie name",
        tooltip:
          "This will be the name of cookie in which you need to store the consents",
        isAdvance: true,
        default: "GCString",
        xs: 12,
        sm: 6,
      },
      {
        name: "gravitoCMP.core.cookieExpiry",
        type: "number",
        label: "Cookie Expiry",
        placeholder: "Enter your cookie expiry",
        tooltip:
          "This will be the expiry of cookie(in days) in which you need to store the consents",
        isAdvance: true,
        default: 365,
        xs: 12,
        sm: 6,
      },
      {
        name: "gravitoCMP.core.languageCode",
        type: "select",
        label: "Default Language",
        placeholder: "Enter your default language code",
        tooltip: "This will be the default language of your CMP.",
        dependencies: ["availableLanguages", "config.gravitoCMP.languages"],
        getOptions: (dependencyData) => {
          const { availableLanguages = [], languages = {} } = dependencyData;
          return Object.keys(languages).map((langCode) => {
            const language = availableLanguages.find(
              (lang) => lang.languageCode === langCode
            );
            return { label: language?.label || langCode, value: langCode };
          });
        },
        default: "en",
        xs: 12,
        sm: 6,
      },
      {
        name: "gravitoCMP.core.privacyPolicyUrl",
        type: "text",
        label: "Privacy Policy URL",
        placeholder: "Enter privacy policy URL",
        tooltip: "This will be the privacy policy URL.",
        required: false,
        default: "",
        xs: 12,
        sm: 6,
      },
    ],
    languages: {
      label: {
        name: "gravitoCMP.languages.{lang}.label",
        default: "English",
      },
      consents: {
        name: "gravitoCMP.languages.{lang}.consents",
        type: "consents",
        label: "Consents",
        helperText:
          "Using this section you will be able to configure the consents.",
        editableOnly: true,
        required: false,
        default: [
          {
            type: "consent",
            id: 1,
            name: "Essential cookies",
            description:
              "Essential cookies and similar technologies are implementing the basic functions of the website such as page navigation, use of forms and shopping cart functionality. Without these technologies the website will not work properly.",
            isConsentable: false,
            cookieCategories: ["Security"],
          },
          {
            type: "consent",
            id: 2,
            name: "Functional cookies",
            description:
              "Functional cookies and similar technologies make it possible to save information that changes the way the website appears to you or functions, e.g. your preferred language.",
            isConsentable: true,
            cookieCategories: ["Functional"],
          },
          {
            type: "consent",
            id: 3,
            name: "Statistical cookies",
            description:
              "Statistical cookies and similar technologies allow us to collect information about how our website is used. This information helps us to improve the content and usability of the website.",
            isConsentable: true,
            cookieCategories: ["Analytics"],
          },
          {
            type: "consent",
            id: 4,
            name: "Marketing & advertising cookies",
            description:
              "Marketing cookies and similar technologies are used to track visitors across websites. The intention is to display ads that are relevant and interesting to you and thus more valuable for us and third-party advertisers.",
            isConsentable: true,
            cookieCategories: ["Marketing"],
          },
          {
            type: "consent",
            id: 5,
            name: "Uncategorised Cookies",
            description:
              "These cookies are currently not assigned to a specific category and may serve multiple functions within the website. They are typically being used to support website functionality, gather insights for improvements, or monitor user engagement. As we further analyze these cookies, they will be categorized accordingly to provide more transparency into their role and purpose.",
            isConsentable: true,
            cookieCategories: ["Uncategorised"],
          },
        ],
        modalSchema: [
          {
            name: "id",
            type: "number",
            label: "Consent ID",
            placeholder: "Enter consent ID",
            tooltip: "This will be the ID of consent.",
            disabled: true,
            default: "",
            required: false,
            xs: 12,
            sm: 12,
          },
          {
            name: "name",
            type: "text",
            label: "Consent Name",
            placeholder: "Enter consent name",
            tooltip: "This will be the Name of consent.",
            default: "",
            required: false,
            xs: 12,
            sm: 12,
          },
          {
            name: "description",
            type: "text",
            label: "Consent Description",
            placeholder: "Enter consent description",
            multiline: true,
            minRows: 3,
            tooltip: "This will be the description of consent.",
            default: "",
            required: false,
            xs: 12,
            sm: 12,
          },
          {
            name: "cookieCategories",
            type: "multiSelect",
            label: "Cookie Categories",
            placeholder: "Select cookie categories",
            getOptions: () => [
              { value: "Security", label: "Security" },
              { value: "Functional", label: "Functional" },
              { value: "Analytics", label: "Analytics" },
              { value: "Marketing", label: "Marketing" },
              { value: "Uncategorised", label: "Uncategorised" },
            ],
            optionValue: "value",
            getOptionLabel: (option) => option.label,
            disabled: true,
            tooltip: "",
            default: [],
            xs: 12,
            sm: 12,
          },
          {
            name: "isConsentable",
            type: "checkbox",
            label: "Is Consentable",
            tooltip:
              "Check this checkbox if you want to show checkbox infront of this consent on second layer.",
            default: false,
            required: false,
            disabled: true,
            xs: 12,
            sm: 12,
          },
        ],
        xs: 12,
        sm: 12,
      },
      text: [
        {
          layerName: "Declaration",
          fields: [
            {
              name: "gravitoCMP.languages.{lang}.text.declaration.header",
              type: "text",
              label: "Tab Header",
              placeholder: "Enter tab header",
              tooltip: "This will be the header of the first tab.",
              required: false,
              default: {
                en: "Declaration",
                da: "Erklæring",
                fr: "Déclaration",
                nl: "Verklaring",
                fi: "Ilmoitus",
                nb: "Erklæring",
                sv: "Förklaring",
                de: "Erklärung",
                pl: "Oświadczenie",
              },
              xs: 12,
              sm: 12,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.declaration.title",
              type: "text",
              label: "Title",
              placeholder: "Enter title",
              tooltip:
                "This will be the title of your CMP banner on first tab.",
              required: false,
              default: {
                da: "Vi skal bruge dit samtykke, for at give en personliggjort oplevelse",
                en: "We need your consent to provide personalized experience",
                fr: "Nous avons besoin de votre consentement pour vous proposer une expérience personnalisée.",
                nl: "We hebben jouw toestemming nodig om een gepersonaliseerde ervaring te bieden",
                fi: "Evästeasetukset",
                nb: "Vi må ha samtykke fra deg for å gi en personlig tilpasset opplevelse",
                sv: "Cookieinställningar",
                de: "Wir benötigen Ihre Zustimmung, um Ihnen ein personalisiertes Erlebnis zu bieten",
                pl: "Potrzebujemy Twojej zgody, aby zapewnić Ci spersonalizowane doświadczenie",
              },
              xs: 12,
              sm: 12,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.declaration.introductionText",
              type: "text",
              label: "Introduction text",
              placeholder: "Enter introduction text",
              multiline: true,
              tooltip:
                "This will be the introductions text of your CMP banner on first tab.",
              required: false,
              default: {
                da: "Vi og vores tredjepartsforhandlere indsamler personlige data (f.eks. IP-adresse, enhedsidentifikator) gennem brugen af cookies og andre tekniske metoder, som lagrer og tilgår data på din enhed, for at give den bedste brugeroplevelse og vise målrettet indhold og annoncer.",
                en: "We and our third-party vendors are collecting personal data (e.g. IP address, device identifier) through the use of cookies and other technical methods which are storing and accessing data on your device to provide the best user experience and show targetted content and advertising.",
                fr: "Nous et nos fournisseurs tiers recueillons des données personnelles (par ex., l'adresse IP, l'identifiant de l'appareil, etc.) en utilisant des cookies et d'autres méthodes techniques qui stockent des données sur votre appareil et y accèdent afin de vous proposer une expérience utilisateur optimale, et vous montrer des contenus et publicités ciblés.",
                nl: "Wij en onze externe leveranciers verzamelen persoonlijke gegevens (zoals IP-adres, apparaat-ID) door het gebruik van cookies en andere technische methoden die gegevens van jouw apparaat opslaan en bekijken om de beste gebruikerservaring te bieden en gerichte inhoud en advertenties weer te geven.",
                fi: "Käytämme evästeitä ja vastaavia tekniikoita, jotta voimme parantaa ja räätälöidä käyttökokemusta ja näyttää mainoksia. Napsauttamalla Hyväksy kaikki hyväksyt evästeiden ja vastaavien tekniikoiden käytön.",
                nb: "Vi og tredjepartsannonsørene våre samler inn personopplysninger (f.eks. IP-adresse, identifikator for enheten din) gjennom bruk av informasjonskapsler og andre tekniske metoder som lagrer og går inn på data på enheten din. Dette for å gi en best mulig brukeropplevelse og vise målrettet innhold og annonser.",
                sv: "Vi använder cookies och liknande tekniker för att förbättra och anpassa din upplevelse samt för att visa annonser. Genom att klicka på Acceptera alla så godkänner du användandet av cookies och liknande tekniker.Du kan ändra dina inställningar när som helst genom att välja Cookieinställningar längst ner på webbplatsen.",
                de: "Wir und die Drittanbieter, mit denen wir zusammenarbeiten, erfassen personenbezogene Daten (z. B. IP-Adresse, Gerätekennung) durch die Verwendung von Cookies und anderen technischen Methoden, die Daten auf Ihrem Gerät speichern und darauf zugreifen, um Ihnen ein optimales Nutzererlebnis zu bieten und zielgerichtete Inhalte und Werbung zu zeigen.",
                pl: "My i nasi zewnętrzni dostawcy zbieramy dane osobowe (np. adres IP, identyfikator urządzenia) za pomocą plików cookie i innych metod technicznych, które przechowują i uzyskują dostęp do danych na Twoim urządzeniu, aby zapewnić Ci najlepsze wrażenia użytkownika oraz wyświetlać spersonalizowane treści i reklamy.",
              },
              xs: 12,
              sm: 12,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.declaration.legalFooter",
              type: "text",
              label: "Legal Footer text",
              placeholder: "Enter legal footer",
              tooltip:
                "This will be the legal footer of your CMP banner on first tab.",
              required: false,
              default: {
                da: "Ved at acceptere, tillader du behandling af dataene i tjenesten. Afvisning kan påvirke brugeroplevelsen.",
                en: "By accepting, you are allowing data processing within the service, rejection can affect the user experience.",
                fr: "En acceptant, vous autorisez le traitement des données dans le cadre du service. Le refus peut nuire à l'expérience utilisateur.",
                nl: "Door te accepteren, sta je gegevensverwerking binnen de dienst toe, afwijzing kan de gebruikerservaring beïnvloeden.",
                fi: "Hyväksymällä sallit tietojesi käsittelyn. Suostumuksesi koskee tätä palvelua, hyväksymättä jättäminen voi vaikuttaa asiakaskokemukseesi.",
                nb: "Ved å godta tillater du databehandling innenfor tjenesten. Avslag kan påvirke brukeropplevelsen.",
                sv: "Genom att acceptera samtycker du till behandlingen av dina uppgifter. Ditt samtycke gäller för denna tjänst, underlåtenhet att göra det kan påverka din kundupplevelse.",
                de: "Durch Akzeptieren erlauben Sie die Datenverarbeitung im Rahmen des Dienstes. Im Falle einer Ablehnung kann jedoch das Nutzererlebnis beeinträchtigt werden.",
                pl: "Akceptując, wyrażasz zgodę na przetwarzanie danych w obrębie tego serwisu. Odmowa może negatywnie wpłynąć na wrażenia użytkownika.",
              },
              xs: 12,
              sm: 12,
            },
          ],
        },
        {
          layerName: "Details",
          fields: [
            {
              name: "gravitoCMP.languages.{lang}.text.details.header",
              type: "text",
              label: "Tab Header",
              placeholder: "Enter tab header",
              tooltip: "This will be the header of the second tab.",
              required: false,
              default: {
                en: "Details",
                da: "Detaljer",
                fr: "Détails",
                nl: "Details",
                fi: "Yksityiskohdat",
                nb: "Detaljer",
                sv: "Detaljer",
                de: "Details",
                pl: "Szczegóły",
              },
              xs: 12,
              sm: 12,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.details.title",
              type: "text",
              label: "Title",
              placeholder: "Enter title",
              tooltip:
                "This will be the title of your CMP banner on second tab.",
              required: false,
              default: {
                da: "Gravito Settings",
                en: "Gravito Settings",
                fr: "Paramètres en matière de consentement",
                nl: "Gravito Settings",
                fi: "Asetukset",
                nb: "Gravito Settings",
                sv: "Cookieinställningar",
                de: "Zustimmungseinstellungen",
                pl: "Ustawienia zgód",
              },
              xs: 12,
              sm: 12,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.details.introductionText",
              type: "text",
              label: "Introduction text",
              placeholder: "Enter introduction text",
              multiline: true,
              tooltip:
                "This will be the introductions text of your CMP banner on second tab.",
              required: false,
              default: {
                da: "Vælg på listen over følgende formål, for at hjælpe os med bedre at tjene dig.",
                en: "Please select from the following list of purposes to help us serve you better.",
                fr: "Veuillez effectuer votre sélection dans la liste d'objectifs suivante afin de nous aider à mieux vous servir.",
                nl: "Maak een keuze uit de volgende lijst met doeleinden, zodat wij je beter van dienst kunnen zijn.",
                fi: 'Voit valita listatuista käyttötarkoituksista ne, jotka haluat hyväksyä ja tallentaa asetukset klikkaamalla "Hyväksy valitut".',
                nb: "Vennligst velg alternativer i følgende liste over formål, slik at vi kan gi deg en bedre tjeneste.",
                sv: "Välj de syften för vilka du godkänner användandet av cookies och liknande tekniker. Du kan ändra dina inställningar när som helst genom att välja Cookieinställningar längst ner på webbplatsen.",
                de: "Bitte treffen Sie eine Auswahl in der folgenden Liste, damit wir Ihnen einen besseren Service bieten können.",
                pl: "Wybierz cele z listy poniżej, abyśmy mogli lepiej spełnić Twoje oczekiwania.",
              },
              xs: 12,
              sm: 12,
            },
          ],
        },
        {
          layerName: "Confirmations",
          fields: [
            {
              name: "gravitoCMP.languages.{lang}.text.confirmationLayer.confirmationForAcceptSelected.heading",
              type: "text",
              label: "Heading for confirmation of accept selected action",
              placeholder:
                "Enter heading for confirmation of accept selected action",
              tooltip:
                "This will be heading of confirmation modal which will appear on clicking accept selected.",
              required: false,
              default: {
                da: "Are you sure you want to disable?",
                en: "Are you sure you want to disable?",
                fr: "Are you sure you want to disable?",
                nl: "Weet je zeker dat je dit wilt uitschakelen?",
                fi: "Oletko varma?",
                nb: "Er du sikker på at du vil slå av?",
                sv: "Är du säker?",
                de: "Sind Sie sicher, dass Sie die Option deaktivieren möchten?",
                pl: "Czy na pewno chcesz wyłączyć?",
              },
              xs: 12,
              sm: 12,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.confirmationLayer.confirmationForAcceptSelected.paragraph",
              type: "text",
              label: "Paragraph for confirmation of accept selected action",
              placeholder:
                "Enter paragraph for confirmation of accept selected action",
              multiline: true,
              tooltip:
                "This will be paragraph of confirmation modal which will appear on clicking accept selected.",
              required: false,
              default: {
                da: "These cookies or other technical methods are important so that we can provide you with a better and more personal user experience.",
                en: "These cookies or other technical methods are important so that we can provide you with a better and more personal user experience.",
                fr: "These cookies or other technical methods are important so that we can provide you with a better and more personal user experience.",
                nl: "Deze cookies en andere technische methodes zijn belangrijk zodat we je een betere en meer persoonlijke gebruikservaring kunnen bieden.",
                fi: "Oletko varma että haluat estää kaikki, valinta voi vaikuttaa käyttökokemukseesi?",
                nb: "Disse informasjonskapslene eller andre tekniske metodene er viktige for at vi skal kunne gi deg en bedre og mer personlig brukeropplevelse.",
                sv: "Dessa cookies, identifierare och tekniker används för att optimera din användarupplevelse, och stänger du av dessa kan det påverka din upplevelse.",
                de: "Diese Cookies oder andere technische Methoden sind wichtig, damit wir Ihnen ein besseres und persönlicheres Nutzererlebnis bieten können.",
                pl: "Te pliki cookie lub inne metody techniczne są ważne, abyśmy mogli zapewnić Ci lepsze i bardziej spersonalizowane wrażenia użytkownika.",
              },
              xs: 12,
              sm: 12,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.confirmationLayer.confirmationForRejectAll.heading",
              type: "text",
              label: "Heading for confirmation of reject all action",
              placeholder:
                "Enter heading for confirmation of reject all action",
              tooltip:
                "This will be heading of confirmation modal which will appear on clicking reject all.",
              required: false,
              default: {
                da: "Are you sure you want to disable?",
                en: "Are you sure you want to disable?",
                fr: "Are you sure you want to disable?",
                nl: "Weet je zeker dat je dit wilt uitschakelen?",
                fi: "Oletko varma?",
                nb: "Er du sikker på at du vil slå av?",
                sv: "Är du säker?",
                de: "Sind Sie sicher, dass Sie die Option deaktivieren möchten?",
                pl: "Czy na pewno chcesz wyłączyć?",
              },
              xs: 12,
              sm: 12,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.confirmationLayer.confirmationForRejectAll.paragraph",
              type: "text",
              label: "Paragraph for confirmation of reject all action",
              placeholder:
                "Enter paragraph for confirmation of reject all action",
              multiline: true,
              tooltip:
                "This will be paragraph of confirmation modal which will appear on clicking reject all.",
              required: false,
              default: {
                da: "These cookies or other technical methods are important so that we can provide you with a better and more personal user experience.",
                en: "These cookies or other technical methods are important so that we can provide you with a better and more personal user experience.",
                fr: "These cookies or other technical methods are important so that we can provide you with a better and more personal user experience.",
                nl: "Deze cookies en andere technische methodes zijn belangrijk zodat we je een betere en meer persoonlijke gebruikservaring kunnen bieden.",
                fi: "Oletko varma että haluat estää kaikki, valinta voi vaikuttaa käyttökokemukseesi?",
                nb: "Disse informasjonskapslene eller andre tekniske metodene er viktige for at vi skal kunne gi deg en bedre og mer personlig brukeropplevelse.",
                sv: "Dessa cookies, identifierare och tekniker används för att optimera din användarupplevelse, och stänger du av dessa kan det påverka din upplevelse.",
                de: "Diese Cookies oder andere technische Methoden sind wichtig, damit wir Ihnen ein besseres und persönlicheres Nutzererlebnis bieten können.",
                pl: "Te pliki cookie lub inne metody techniczne są ważne, abyśmy mogli zapewnić Ci lepsze i bardziej spersonalizowane wrażenia użytkownika.",
              },
              xs: 12,
              sm: 12,
            },
          ],
        },
        {
          layerName: "Common Terms",
          fields: [
            {
              name: "gravitoCMP.languages.{lang}.text.commonTerms.acceptAll",
              type: "text",
              label: "Accept All Label",
              placeholder: "Enter accept all label",
              tooltip: "This will be the label for accept all button",
              required: false,
              default: {
                da: "Accepter alle",
                en: "Accept all",
                fr: "Tout accepter",
                nl: "Alles accepteren",
                fi: "Hyväksy kaikki",
                nb: "Godta alle",
                sv: "Acceptera alla",
                de: "Alle akzeptieren",
                pl: "Akceptuj wszystkie",
              },
              xs: 12,
              sm: 6,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.commonTerms.save",
              type: "text",
              label: "Accept Selected Label",
              placeholder: "Enter accept selected label",
              tooltip: "This will be the label for accept selected button",
              required: false,
              default: {
                da: "Afvis alle",
                en: "Accept selected",
                fr: "Accept selected",
                nl: "Geselecteerde opties accepteren",
                fi: "Hyväksy valitut",
                nb: "Godta valgte",
                sv: "Acceptera valda",
                de: "Auswahl akzeptieren",
                pl: "Akceptuj wybrane",
              },
              xs: 12,
              sm: 6,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.commonTerms.rejectAll",
              type: "text",
              label: "Reject All Label",
              placeholder: "Enter reject all label",
              tooltip: "This will be the label for reject all button",
              required: false,
              default: {
                da: "Afvis alle",
                en: "Reject all",
                fr: "Tout refuser",
                nl: "Alles afwijzen",
                fi: "Estä kaikki",
                nb: "Avvis alle",
                sv: "Avvisa alla",
                de: "Alle akzeptieren",
                pl: "Odrzuć wszystkie",
              },
              xs: 12,
              sm: 6,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.commonTerms.settings",
              type: "text",
              label: "Settings Label",
              placeholder: "Enter settings label",
              tooltip: "This will be the label for settings button",
              required: false,
              default: {
                da: "Indstillinger",
                en: "Settings",
                fr: "Paramètres",
                nl: "Instellingen",
                fi: "Asetukset",
                nb: "Innstillinger",
                sv: "Inställningar",
                de: "Einstellungen",
                pl: "Ustawienia",
              },
              xs: 12,
              sm: 6,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.commonTerms.cancel",
              type: "text",
              label: "Cancel Label",
              placeholder: "Enter cancel label",
              tooltip: "This will be the label for cancel button",
              required: false,
              default: {
                da: "Annuller",
                en: "Cancel",
                fr: "Annuler",
                nl: "Annuleren",
                fi: "Peruuta",
                nb: "Avbryt",
                sv: "Avbryt",
                de: "Abbrechen",
                pl: "Anuluj",
              },
              xs: 12,
              sm: 6,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.commonTerms.confirm",
              type: "text",
              label: "Confirm Label",
              placeholder: "Enter confirm label",
              tooltip: "This will be the label for confirm button",
              required: false,
              default: {
                da: "Bekræft",
                en: "Confirm",
                fr: "Confirmer",
                nl: "Bevestigen",
                fi: "Vahvista",
                nb: "Bekreft",
                sv: "Bekräfta",
                de: "Bestätigen",
                pl: "Potwierdź",
              },
              xs: 12,
              sm: 6,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.commonTerms.consent",
              type: "text",
              label: "Consent Check Box Label",
              placeholder: "Enter consent check box label",
              tooltip: "This will be the label for consent check box.",
              required: false,
              default: {
                da: "Samtykke",
                en: "Consent",
                fr: "Consentement",
                nl: "Toestemming",
                fi: "Suostumus",
                nb: "Samtykke",
                sv: "Samtycke",
                de: "Einwilligung",
                pl: "Zgoda",
              },
              xs: 12,
              sm: 6,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.commonTerms.privacyPolicyLabel",
              type: "text",
              label: "Privacy Policy Label",
              placeholder: "Enter privacy policy label",
              tooltip: "This will be the label for your privacy policy URL.",
              required: false,
              default: {
                da: "Privatlivspolitik",
                en: "Privacy Policy",
                fr: "Privacy Policy",
                nl: "Privacybeleid",
                fi: "Tietosuoja",
                nb: "Personvernerklæring",
                sv: "Cookiepolicy",
                de: "Datenschutzerklärung",
                pl: "Polityka prywatności",
              },
              xs: 12,
              sm: 6,
            },
          ],
        },
        {
          layerName: "Cookie Report",
          fields: [
            {
              name: "gravitoCMP.languages.{lang}.text.cookieReportLayer.buttonTitle",
              type: "text",
              label: "Button Title",
              placeholder: "Enter button title",
              tooltip:
                "Title for button which will be used for opening cookie report layer.",
              required: false,
              default: {
                da: "Cookie-rapport",
                en: "Cookie Report",
                fr: "Cookie Report",
                nl: "Cookierapport",
                fi: "Evästeraportti",
                nb: "Informasjonskapsel-rapport",
                sv: "Cookie rapport",
                de: "Cookie-Bericht",
                pl: "Raport o plikach cookie",
              },
              xs: 12,
              sm: 6,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.cookieReportLayer.heading",
              type: "text",
              label: "Heading",
              placeholder: "Enter heading",
              tooltip:
                "Text that should be used as heading for cookie report layer.",
              required: false,
              default: {
                da: "Cookie Reports layer header",
                en: "Cookie Reports layer header",
                fr: "Rapport en matière de cookies",
                nl: "Cookie Reports layer header",
                fi: "Sivuston käyttämät evästeet",
                nb: "Cookie Reports layer header",
                sv: "Webplatsen använder följande cookies",
                de: "Cookie-Bericht",
                pl: "Raport o plikach cookie",
              },
              xs: 12,
              sm: 6,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.cookieReportLayer.introductionText",
              type: "text",
              label: "Introduction Text",
              placeholder: "Enter introduction text",
              multiline: true,
              tooltip:
                "Text that should be used as introduction text for cookie report layer.",
              required: false,
              default: {
                da: "Cookies er små tekstfiler, som hjemmesider kan bruge til at gøre brugeroplevelsen mere effektiv. Vi bruger cookies på hjemmesiden. Vi bruger cookies på denne hjemmeside til at forbedre brugeroplevelsen, og til bedre at tjene dig. Baseret på vores scanning er dette de cookies, der bliver anvendt, baseret på de præferencer du har indstillet.",
                en: "Cookies are small text files that websites can use to make the user experience more efficient. We use cookies on the website. We use cookies on this website to improve the visitor experience and to better serve you. Based on our scan, this is how the cookies that will be used based on the preference set by you.",
                fr: "Les cookies sont de petits fichiers texte que les sites internet peuvent utiliser pour améliorer l'expérience utilisateur. Nous utilisons des cookies sur le site internet. Nous en utilisons sur celui-ci pour améliorer l'expérience utilisateur et pour mieux vous servir. Sur la base de notre analyse, voici comment les cookies seront utilisés en fonction des préférences que vous avez définies.",
                nl: "Cookies zijn kleine tekstbestanden die websites kunnen gebruiken om de gebruikerservaring efficiënter te maken. Wij gebruiken cookies op de website. Wij gebruiken cookies op deze website om de bezoekerservaring te verbeteren en je beter van dienst te kunnen zijn. Op basis van onze scan zijn dit de cookies die gebruikt zullen worden op basis van de door jouw ingestelde voorkeur.",
                fi: "Evästeitä käytetään sivuston teknisiin toimintoihin, käyttökokemuksen optimointiin ja käyttäjien seurantaan. Tämä raportti sisältää tiedot evästeistä joita sivusto käyttää joko teknisiin käyttötarkoituksiin (ennen suostumusta) ja evästeet ovat luonteeltaan pakollisia sivuston toiminnon kannalta, tai evästeitä käytetään suostumuksen jälkeen (käyttäjä hyväksyy kaikki käyttötarkoitukset). Riippuen suostumuksistasi kaikkia listattuja evästeitä ei välttämättä aseteta selaimeesi.",
                nb: "Informasjonskapsler er små tekstfiler som nettsteder kan bruke for å gjøre brukeropplevelsen mer effektiv. Vi bruker informasjonskapsler på nettstedet. Vi bruker informasjonskapsler på dette nettstedet for å gjøre opplevelsen bedre for besøkende og gi deg en bedre tjeneste. Basert på vår skanning er det slik informasjonskapslene kommer til å bli brukt, basert på innstillingene du har gjort.",
                sv: "Webplatsen använder cookien för tekniska skäl för att optimera användarupplevelsen och följa användaren. Denna rapport innehåller information om cookien som används antingen för tekniska skäl (före samtycken)och är oblikatoriska för att webplatsen kan fungera och om cookien som används efter samtycken(användaren godkänner allt). Beroende på dina samtycken används inte alla listade cookien nödvändigtvis på din webläsare.",
                de: "Cookies sind kleine Textdateien, die von Websites verwendet werden können, um das Nutzererlebnis effizienter zu gestalten. Wir verwenden Cookies auf dieser Webseite, um das Besuchererlebnis zu verbessern und um Ihnen einen besseren Service zu bieten. Basierend auf unserem Scan werden die Cookies auf der Grundlage der von Ihnen eingestellten Präferenzen wie folgt verwendet.",
                pl: "Pliki cookie to małe pliki tekstowe, które strony internetowe mogą wykorzystywać, aby uczynić korzystanie z nich bardziej efektywnym. Używamy plików cookie na tej stronie w celu poprawy doświadczeń odwiedzających i lepszego dostosowania się do ich potrzeb. Na podstawie naszego skanowania przedstawiamy sposób wykorzystania plików cookie zgodnie z wybranymi przez Ciebie preferencjami.",
              },
              xs: 12,
              sm: 12,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.cookieReportLayer.beforeAcceptHeader",
              type: "text",
              label: "Before accepting data heading",
              placeholder: "Enter heading",
              tooltip:
                "Text that should be used as Heading for the section cookie report layer which will be showing data before Accepting the consent.",
              required: false,
              default: {
                da: "Før accept",
                en: "Before Accepting",
                fr: "Avant d'accepter",
                nl: "Voor het accepteren",
                fi: "Evästeet ennen suostumusta (pakolliset ja toiminnalliset evästeet)",
                nb: "Før du aksepterer",
                sv: "Cookien före samtycken (nödvändiga och funktionella cookien)",
                de: "Vor der Annahme",
                pl: "Przed akceptacją",
              },
              xs: 12,
              sm: 6,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.cookieReportLayer.afterAcceptHeader",
              type: "text",
              label: "After accepting data heading",
              placeholder: "Enter heading",
              tooltip:
                "Text that should be used as heading for the section cookie report layer which will be showing data after Accepting the consent.",
              required: false,
              default: {
                da: "Efter accept",
                en: "After Accepting",
                fr: "Après avoir accepté",
                nl: "Na het accepteren",
                fi: "Evästeet suostumushyväksynnän jälkeen",
                nb: "Etter at du har akseptert",
                sv: "Cookien efter samtycken",
                de: "Nach der Annahme",
                pl: "Po akceptacji",
              },
              xs: 12,
              sm: 6,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.cookieReportLayer.nameHeader",
              type: "text",
              label: "Header for Name",
              placeholder: "Enter header for name",
              tooltip:
                "This text will be used as a header for name coloumn in cookie report layer.",
              required: false,
              default: {
                da: "Navn",
                en: "Name",
                fr: "Nom",
                nl: "Naam",
                fi: "Nimi",
                nb: "Navn",
                sv: "Namn",
                de: "Name",
                pl: "Nazwa",
              },
              xs: 12,
              sm: 6,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.cookieReportLayer.domainHeader",
              type: "text",
              label: "Header for domain",
              placeholder: "Enter header for domain",
              tooltip:
                "This text will be used as a header for domain coloumn in cookie report layer.",
              required: false,
              default: {
                da: "Domæne",
                en: "Domain",
                fr: "Domaine",
                nl: "Domein",
                fi: "Domain-nimi",
                nb: "Domene",
                sv: "Domän",
                de: "Domäne",
                pl: "Domena",
              },
              xs: 12,
              sm: 6,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.cookieReportLayer.descriptionHeader",
              type: "text",
              label: "Header for description",
              placeholder: "Enter header for description",
              tooltip:
                "This text will be used as a header for description coloumn in cookie report layer.",
              required: false,
              default: {
                da: "Beskrivelse",
                en: "Description",
                fr: "Description",
                nl: "Omschrijving",
                fi: "Kuvaus",
                nb: "Beskrivelse",
                sv: "Syfte",
                de: "Beschreibung",
                pl: "Opis",
              },
              xs: 12,
              sm: 6,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.cookieReportLayer.cookieTypeHeader",
              type: "text",
              label: "Header for cookie type",
              placeholder: "Enter header for cookie type",
              tooltip:
                "This text will be used as a header for cookie type coloumn in cookie report layer.",
              required: false,
              default: {
                da: "Cookie-type",
                en: "Cookie Type",
                fr: "Type de cookies",
                nl: "Cookietype",
                fi: "Tyyppi",
                nb: "Cype informasjonskapsel",
                sv: "Typ",
                de: "Cookie-Typ",
                pl: "Rodzaj pliku cookie",
              },
              xs: 12,
              sm: 6,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.cookieReportLayer.expiryHeader",
              type: "text",
              label: "Header for expiry",
              placeholder: "Enter header for expiry",
              tooltip:
                "This text will be used as a header for expiry coloumn in cookie report layer.",
              required: false,
              default: {
                da: "EUdløber om dage",
                en: "Expiry in days",
                fr: "Expiration en jours",
                nl: "Vervaldatum in dagen",
                fi: "Elinikä",
                nb: "Utløper om dager",
                sv: "Livslängd",
                de: "Verfall in Tagen",
                pl: "Ważność w dniach",
              },
              xs: 12,
              sm: 6,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.cookieReportLayer.httpOnlyHeader",
              type: "text",
              label: "Header for httpOnly",
              placeholder: "Enter header for httpOnly",
              tooltip:
                "This text will be used as a header for httpOnly coloumn in cookie report layer.",
              required: false,
              default: {
                da: "Udløber om dage",
                en: "Http Only",
                fr: "Http uniquement",
                nl: "Alleen http",
                fi: "Palvelineväste",
                nb: "Kun Http",
                sv: "Server cookie",
                de: "Nur HTTP",
                pl: "Tylko HTTP",
              },
              xs: 12,
              sm: 6,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.cookieReportLayer.size",
              type: "text",
              label: "Header for size",
              placeholder: "Enter header for size",
              tooltip:
                "This text will be used as a header for size coloumn in cookie report layer.",
              required: false,
              default: {
                da: "Størrelse",
                en: "Size",
                fr: "Taille",
                nl: "Grootte",
                fi: "Koko",
                nb: "Størrelse",
                sv: "Storlek",
                de: "Größe",
                pl: "Rozmiar",
              },
              xs: 12,
              sm: 6,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.cookieReportLayer.pixelRequestHeader",
              type: "text",
              label: "Header for pixel request",
              placeholder: "Enter header for pixel request",
              tooltip:
                "This text will be used as a header for pixel request coloumn in cookie report layer.",
              required: false,
              default: {
                da: "Pixelanmodninger",
                en: "Pixel Requests",
                fr: "Demandes de pixel",
                nl: "Pixelverzoeken",
                fi: "Pikselipyynnöt",
                nb: "Pixelforespørsler",
                sv: "Pixelbegäran",
                de: "Pixelanfragen",
                pl: "Żądania pikseli",
              },
              xs: 12,
              sm: 6,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.cookieReportLayer.noCookieDataMessage",
              type: "text",
              label: "No cookie data message",
              placeholder: "Enter no cookie data message",
              tooltip:
                "This text will be used as text when their is no cookie report data available.",
              required: false,
              default: {
                da: "Ingen scanningsresultater tilgængelige",
                en: "No scan results available",
                fr: "Aucun résultat d'analyse disponible",
                nl: "Geen scanresultaten beschikbaar",
                fi: "Ei evästeskannauksen tuloksia saatavilla.",
                nb: "Ingen skanningresultater tilgjengelige",
                sv: "Inga cookie-scan resultat till hands.",
                de: "Keine Scanergebnisse verfügbar",
                pl: "Brak dostępnych wyników skanowania",
              },
              xs: 12,
              sm: 12,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.cookieReportLayer.noCookieDataBeforeAcceptingMsg",
              type: "text",
              label: "No data message for before accepting section",
              placeholder: "Enter no cookie data message",
              tooltip:
                "This text will be used as text when their is no cookie report data available for  before accepting section.",
              required: false,
              default: {
                da: "Ingen cookies blev fundet før accept af samtykke på dette domæne.",
                en: "No cookies were found before Accepting the consents on this domain.",
                fr: "Aucun cookie n'a été trouvé avant d'accorder les consentements sur ce domaine.",
                nl: "Er zijn geen cookies gevonden voor het Accepteren van de toestemmingen op dit domein.",
                fi: "Evästeitä ei löytynyt ennen suostumushyväksyntää.",
                nb: "Ingen informasjonskapsler ble funnet før samtykkene på dette domenet ble Godtatt.",
                sv: "Inga cookien hittades före samtycken.",
                de: "Es wurden keine Cookies gefunden, bevor Sie die Zustimmungen für diese Domain akzeptiert haben.",
                pl: "Nie wykryto plików cookie przed zaakceptowaniem zgód na tej domenie.",
              },
              xs: 12,
              sm: 12,
            },
            {
              name: "gravitoCMP.languages.{lang}.text.cookieReportLayer.noCookieDataAfterAcceptingMsg",
              type: "text",
              label: "No data message for After accepting section",
              placeholder: "Enter no cookie data message",
              tooltip:
                "This text will be used as text when their is no cookie report data available for after accepting section.",
              required: false,
              default: {
                da: "No cookies were found after Accepting the consents on this domain.",
                en: "No cookies were found after Accepting the consents on this domain.",
                fr: "Aucun cookie n'a été trouvé après avoir accordé les consentements sur ce domaine.",
                nl: "No cookies were found after Accepting the consents on this domain.",
                fi: "Evästeitä ei löytynyt suostumushyväksynnän jälkeen.",
                nb: "No cookies were found after Accepting the consents on this domain ",
                sv: "Inga cookien hittades efter samtycken.",
                de: "Es wurden keine Cookies gefunden, nachdem Sie die Zustimmungen für diese Domain akzeptiert haben.",
                pl: "Nie wykryto plików cookie po wyrażeniem zgód na tej domenie.",
              },
              xs: 12,
              sm: 12,
            },
          ],
        },
      ],
    },
    style: [
      {
        name: "gravitoCMP.style.useCustomHtml",
        type: "checkbox",
        label: "Do you want to use custom HTML?",
        tooltip: "This will enable the custom HTML for your CMP.",
        required: false,
        default: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "gravitoCMP.style.customCSS",
        type: "editor",
        language: "css",
        label: "Custom CSS",
        tooltip: "This will be the custom CSS for your CMP.",
        default: "",
        xs: 12,
        sm: 12,
      },
    ],
  },
};

export default schema;
