import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosRequest } from "../helpers/index";

export const getMicroprofile = createAsyncThunk(
  "microprofile/getMicroprofile",
  async (model, thunkAPI) => {
    try {
      let res = await getAxiosRequest().get(
        `/customerdata/${model.customerId}/${model.matchOnId}`
      );
      let data = res?.data[0];
      if (data?.message) {
        data.message = JSON.parse(data.message);
      }
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
