import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAxiosRequest, getContextObject } from "../helpers/index";

export const getCookieScanReport = createAsyncThunk(
  "manageCookieScanThunk/getCookieScanReport",
  async (model, thunkAPI) => {
    try {
      var customerId = getContextObject() ? getContextObject().customerId : 0;
      let response = await getAxiosRequest().get(
        `/cmpconfigurator/${customerId}/cookiescan`
      );
      return response.data ? JSON.parse(response.data) : [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateCookieData = createAsyncThunk(
  "manageCookieScanThunk/updateCookieData",
  async (model, thunkAPI) => {
    try {
      var customerId = getContextObject() ? getContextObject().customerId : 0;
      let response = await getAxiosRequest().put(
        `/cmpconfigurator/${customerId}/cookiescan/cookie`,
        model
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const publishDomainCookieData = createAsyncThunk(
  "manageCookieScanThunk/publishDomainCookieData",
  async (model, thunkAPI) => {
    try {
      var customerId = getContextObject() ? getContextObject().customerId : 0;
      let response = await getAxiosRequest().put(
        `/cmpconfigurator/${customerId}/${model.domainId}/cookiescan/publish`,
        model.publishModel
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createCookieData = createAsyncThunk(
  "manageCookieScanThunk/createCookieData",
  async (model, thunkAPI) => {
    try {
      var customerId = getContextObject() ? getContextObject().customerId : 0;
      model.customerId = customerId;
      let response = await getAxiosRequest().post(
        `/cmpconfigurator/${customerId}/cookiescan/cookie`,
        model
      );
      return response.data;
      
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteCookieData = createAsyncThunk(
  "manageCookieScanThunk/deleteCookieData",
  async (model, thunkAPI) => {
    try {
      var customerId = getContextObject() ? getContextObject().customerId : 0;
      var cookieId = model.Id;
      let response = await getAxiosRequest().delete(
        `/cmpconfigurator/${customerId}/cookiescan/cookie/${cookieId}`,
        model
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
