import { createSlice } from "@reduxjs/toolkit";
import {
  getCurrentCustomer,
  getCreditsList,
  getUserProfile,
  registerNewDomains,
  deleteTeamMember,
  inviteTeamMember,
  getVoucherByCode,
  purchaseCredits,
  updateUserProfile,
  addDomainLicense,
  deactivateDomainLicense,
  getSubscriptions,
  getDataAccessTokens,
  getCustomerData,
  addDomainForCookieScan,
  getActiveCookieScanDomains,
  deleteCookieScanDomain,
} from "../thunks/manageAccount";
import * as _ from "lodash";
import moment from "moment";
import AppConfig from "../constants/appConfig";
import NotificationManager from "react-notifications/lib/NotificationManager";

const getMonthsForCost = (allCosts) => {
  let res = [];
  if (allCosts && allCosts.length > 0) {
    allCosts = _.orderBy(allCosts, ["key"], ["asc"]);
    let monthKey = "key";
    const getMonthFromDate = (dateString) => {
      let date = moment(dateString, "YYYY-MM-DD");
      let month = date.format("MMMM");
      let year = date.format("YYYY");
      let currentMonth = moment().format("MMMM");
      let currentYear = moment().format("YYYY");
      let currentText =
        currentMonth === month && currentYear === year ? "(Current)" : "";
      return {
        label: `${month}-${year} ${currentText}`,
        sourceDate: dateString,
      };
    };
    allCosts.forEach((cost, index) => {
      let costMonth = getMonthFromDate(cost[monthKey]);
      res.push(costMonth);
    });
    return _.uniqBy([...res], "label");
  }
  return res;
};

const getCostForMonth = (allCost, month) => {
  if (month) {
    let res = _.find(allCost, (c, i) => {
      return c.key === month.sourceDate;
    });
    return res;
  } else {
    return null;
  }
};

const initState = {
  currentCustomer: null,
  customerVouchers: [],
  gravitoVouchers: [],
  filteredGravitoVouchers: [],
  voucher: null,
  voucherByCode: null,
  voucherSearchError: null,
  loading: false,
  error: null,
  filterBy: "",
  inviteModel: null,
  currentUser: null,
  updateUser: null,
  ledgerBalance: null,
  selectedTeamMember: null,
  updatedCustomer: null,
  costs: null,
  constMonths: [],
  activeMonth: null,
  allcosts: [],
  licenses: [],
  nonLicensedDomains: [],
  addDomainLicenseModel: null,
  updateDomainLicenseModel: null,
  creditToken: null,
  paymentSuccessStatus: false,
  paymentError: false,
  creditList: [],
  StripeCustomerId: null,
  subscriptionDomains: [],
  nonSubscriptionDomains: [],
  dataAccessTokens: [],
  customerData: null,
};

const manageAccountSlice = createSlice({
  name: "manageAccountSlice",
  reducers: {
    changeCostCenterMonth(state, action) {
      let costsObj = getCostForMonth(state.allcosts, action.payload);
      let costs = costsObj ? JSON.parse(costsObj.value) : null;
      state.activeMonth = action.payload;
      state.costs = costs;
    },
  },
  initialState: initState,
  extraReducers: (builder) => {
    builder.addCase(getCurrentCustomer.fulfilled, (state, action) => {
      let currentCustomer = null;
      if (action.payload.customer && action.payload.customer.countryCode) {
        let country = _.filter(AppConfig.countries, {
          code: action.payload.customer.countryCode.trim(),
        }).map(function (a) {
          return {
            value: a.code,
            label: a.name + " (" + a.dial_code + ")",
          };
        });

        currentCustomer = action.payload.customer;
        currentCustomer.countryCode = country;
      } else {
        currentCustomer = action.payload.customer;
        currentCustomer.countryCode = "";
      }

      var licensedDomains = action.payload.licenses.map(
        (license) => license.domainId
      );

      const nonLicensedDomains = [...currentCustomer.domains];
      licensedDomains.forEach((element) => {
        _.remove(nonLicensedDomains, {
          id: element,
        });
      });

      let costs = getCostForMonth(
        action.payload.costs,
        getMonthsForCost(action.payload.costs)[
          getMonthsForCost(action.payload.costs).length - 1
        ]
      );

      state.loading = false;
      state.currentCustomer = currentCustomer;
      state.allcosts = action.payload.costs;
      state.costMonths = getMonthsForCost(action.payload.costs);
      state.activeMonth =
        getMonthsForCost(action.payload.costs).length > 0
          ? getMonthsForCost(action.payload.costs)[
              getMonthsForCost(action.payload.costs).length - 1
            ]
          : "";
      state.costs = costs ? JSON.parse(costs.value) : null;
      state.licenses = action.payload.licenses;
      state.nonLicensedDomains = nonLicensedDomains;
    });
    builder.addCase(getCurrentCustomer.rejected, (state, action) => {
      state.error = action.payload;
    });

    builder.addCase(getCreditsList.fulfilled, (state, action) => {
      state.creditList = action.payload;
    });
    builder.addCase(getCreditsList.rejected, (state, action) => {
      NotificationManager.error("Error while fetching credits list");
      state.creditList = [];
    });

    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      let currentUser = null;
      if (action.payload && action.payload.countryCode) {
        let country = _.filter(AppConfig.countries, {
          dial_code: action.payload.countryCode.trim(),
        }).map(function (a) {
          return {
            value: a.dial_code,
            label: a.name + " (" + a.dial_code + ")",
          };
        });

        currentUser = action.payload;
        currentUser.countryCode = country;
      } else {
        currentUser = action.payload;
        currentUser.countryCode = "";
      }
      state.currentUser = currentUser;
    });

    builder.addCase(getUserProfile.rejected, (state, action) => {});

    builder.addCase(registerNewDomains.fulfilled, (state, action) => {
      NotificationManager.success(
        "New domain(s) were successfully added to queue and will reflect in your customership after sometime."
      );
    });

    builder.addCase(registerNewDomains.rejected, (state, action) => {
      NotificationManager.error(
        "We could not add new domains to your customership."
      );
    });

    builder.addCase(deleteTeamMember.fulfilled, (state, action) => {
      NotificationManager.success(
        `Selected team member has been removed successfully.`
      );
      state.loading = false;
    });
    builder.addCase(deleteTeamMember.rejected, (state, action) => {
      NotificationManager.error(
        `Sorry, we could not remove the selected team member, please contact Gravito support.`
      );
      state.loading = false;
    });

    builder.addCase(inviteTeamMember.fulfilled, (state, action) => {
      NotificationManager.success(
        `An invite was successfully sent to ${action.payload.email}.`
      );
      state.loading = false;
    });

    builder.addCase(inviteTeamMember.rejected, (state, action) => {
      if (action.payload) {
        NotificationManager.warning(
          `We can not send invite as, ${action.payload.email} ${action.payload.error}`
        );
      } else {
        NotificationManager.error(
          `We could not send an invite to ${state.inviteModel.email} .`
        );
      }
      state.loading = false;
    });
    builder.addCase(getVoucherByCode.fulfilled, (state, action) => {
      var notFound = null;
      if (action.payload === null) {
        notFound = "This voucher is not valid, please check !!";
      }
      state.voucherByCode = action.payload;
      state.voucherSearchError = notFound;
    });
    builder.addCase(getVoucherByCode.rejected, (state, action) => {
      state.error = "This voucher is not valid, please check !!";
      state.customerVouchers = [];
    });
    builder.addCase(purchaseCredits.fulfilled, (state, action) => {
      NotificationManager.success(`Successfully purchased the credits`);
      state.creditToken = null;
      state.paymentSuccessStatus = true;
      state.paymentError = false;
    });

    builder.addCase(purchaseCredits.rejected, (state, action) => {
      NotificationManager.error("Error while purchasing credit");
      state.paymentError = true;
      state.paymentSuccessStatus = false;
    });

    builder.addCase(updateUserProfile.fulfilled, (state, action) => {});
    builder.addCase(updateUserProfile.rejected, (state, action) => {});

    builder.addCase(addDomainLicense.fulfilled, (state, action) => {
      NotificationManager.success(
        `A new License was added for selected domain.`
      );
    });
    builder.addCase(addDomainLicense.rejected, (state, action) => {
      NotificationManager.error(`${action.payload}`);
    });

    builder.addCase(deactivateDomainLicense.fulfilled, (state, action) => {
      NotificationManager.success(`Domain license deactivated successfully.`);
    });
    builder.addCase(deactivateDomainLicense.rejected, (state, action) => {
      NotificationManager.error(`${action.payload}`);
    });

    builder.addCase(getSubscriptions.fulfilled, (state, action) => {
      let subscriptionData = {
        StripeCustomerId: action.payload.StripeCustomerId,
        subscriptionDomains: [],
        nonSubscriptionDomains: [],
      };
      if (action.payload.Domain) {
        action.payload.Domain.forEach((subDomain) => {
          let domain = state.currentCustomer.domains.find(
            (domain) => domain.id === subDomain.DomainId
          );
          if (domain) {
            subscriptionData.subscriptionDomains.push({
              ...domain,
              ...subDomain.StripeDomainSubscription[0],
            });
          }
        });

        state.currentCustomer.domains.forEach((domain) => {
          let subscription = subscriptionData.subscriptionDomains.find(
            (subDomain) => subDomain.id === domain.id
          );
          if (!subscription) {
            subscriptionData.nonSubscriptionDomains.push(domain);
          }
        });
      }

      state.StripeCustomerId = subscriptionData.StripeCustomerId;
      state.subscriptionDomains = subscriptionData.subscriptionDomains;
      state.nonSubscriptionDomains = subscriptionData.nonSubscriptionDomains;
    });

    builder.addCase(getDataAccessTokens.fulfilled, (state, action) => {
      state.dataAccessTokens = action.payload;
    });
    builder.addCase(getDataAccessTokens.rejected, (state, action) => {
      NotificationManager.error("Error while fetching data access token list");
      state.dataAccessTokens = [];
    });

    builder.addCase(getCustomerData.fulfilled, (state, action) => {
      state.customerData = action.payload;
    });
    builder.addCase(getCustomerData.rejected, (state, action) => {
      NotificationManager.error("Error while fetching customer data");
      state.customerData = null;
    });

    builder.addCase(addDomainForCookieScan.fulfilled, (state, action) => {
      NotificationManager.success("Domain added for cookie scan");
    });
    builder.addCase(addDomainForCookieScan.rejected, (state, action) => {
      NotificationManager.error("Error while adding domain for cookie scan");
    });

    builder.addCase(deleteCookieScanDomain.fulfilled, (state, action) => {
      NotificationManager.success("Domain removed from cookie scan");
    });
    builder.addCase(deleteCookieScanDomain.rejected, (state, action) => {
      NotificationManager.error("Error while removing domain from cookie scan");
    });

    builder.addCase(
      getActiveCookieScanDomains.fulfilled,
      (state, action) => {}
    );
    builder.addCase(getActiveCookieScanDomains.rejected, (state, action) => {});
  },
});

export const { changeCostCenterMonth } = manageAccountSlice.actions;
export default manageAccountSlice.reducer;
