import { createSlice } from "@reduxjs/toolkit";
import { getMicroprofile } from "../thunks/microprofile";

const initialState = {
  loading: false,
};

const microprofileSlice = createSlice({
  name: "microprofileSlice",
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getMicroprofile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMicroprofile.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getMicroprofile.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default microprofileSlice.reducer;
