import { configureStore, compose } from "@reduxjs/toolkit";
import authReducer from "../slice/auth";
import domainsReducer from "../slice/domains";
import manageAccountReducer from "../slice/manageAccount";
import scanCookiesReducer from "../slice/cookieScan";
import manageCookieScanReducer from "../slice/manageCookieScan";
import applicationReducer from "../slice/application";
import connectorsReducer from "../slice/connector";
import gvlUpdatesReducer from "../slice/gvlUpdates";
import firstPartyDomainsReducer from "../slice/firstPartyDomains";
import microprofileReducer from "../slice/microprofile";
import reportReducer from "../slice/reporting";
import liteCmpConfigReducer from "../slice/liteCmpConfig";
import tcfCmpConfigReducer from "../slice/tcfConfig";
import proCmpConfigReducer from "../slice/proCmpConfig";
import customerDashboardReducer from "../slice/customerDashboard";
import compareTrendsReducer from "../slice/compareTrends";
import feedbackReducer from "../slice/feedback";
import registrationReducer from "../slice/registeration";
import helpReducer from "../slice/help";
// import { composeWithDevTools } from "redux-devtools-extension";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const Store = configureStore({
  reducer: {
    authReducer,
    domainsReducer,
    manageAccountReducer,
    scanCookiesReducer,
    manageCookieScanReducer,
    applicationReducer,
    connectorsReducer,
    gvlUpdatesReducer,
    firstPartyDomainsReducer,
    microprofileReducer,
    reportReducer,
    liteCmpConfigReducer,
    tcfCmpConfigReducer,
    proCmpConfigReducer,
    customerDashboardReducer,
    compareTrendsReducer,
    feedbackReducer,
    registrationReducer,
    helpReducer,
  },
  composeEnhancers: composeEnhancers(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default Store;
